import {
  InfoCircleOutlined,
  LeftOutlined,
  MinusOutlined,
  PlusOutlined,
  RightOutlined,
  ScheduleOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import {
  Button,
  Calendar,
  Col,
  DatePicker,
  Flex,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Steps,
  Switch,
  Tooltip,
  Typography,
  message,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { CSSProperties, useEffect, useMemo, useState } from "react";
import { NumberFormatBase } from "react-number-format";
import { useIntercom } from "react-use-intercom";
import {
  AdmissionFlowApi,
  DepartmentApi,
  EmployeeApi,
  EmployerApi,
  EmploymentRelationshipApi,
  IntercomApi,
} from "src/api";
import { FirstAccessFlowDocumentation, LogoColoridaSimples } from "src/assets/images";
import AdmissionsLimitModal, { AdmissionsLimitModalData } from "src/pages/Private/Dashboard/AdmissionsLimitModal";
import EmployeeLimitReachedModal from "src/pages/Private/Dashboard/EmployeeLimitReachedModal";
import { ExpiredSessionError, dictionary, formatToBRL, i18n } from "src/utils";
import { INTERCOM_FIELDS } from "src/utils/enums/intercom.enum";
import { SelectInsert } from "..";
import GoodErrorMessage from "../Modal/GoodErrorMessage";

const { Title } = Typography;
const { Item } = Form;
const { Option } = Select;

const inputNumberControls = { upIcon: <PlusOutlined />, downIcon: <MinusOutlined /> };

const titleStyle: CSSProperties = {
  margin: 0,
  color: "#884EA6",
};

type NewAdmissionModalProps = {
  open: boolean;
  onCancel: () => void;
  reloadAdmissions?: () => Promise<boolean>;
};

export default function NewAdmissionModal({ open, onCancel, reloadAdmissions }: NewAdmissionModalProps) {
  const [messageApi, messageContextHolder] = message.useMessage();

  const [step, setStep] = useState<1 | 2>(1);

  // Step 1
  const [form] = Form.useForm();

  const [departments, setDepartments] = useState<Department[]>([]);
  const [employers, setEmployers] = useState<Employer[]>([]);
  const [employmentRelationships, setEmploymentRelationships] = useState<EmploymentRelationship[]>([]);
  const [admissionFlows, setAdmissionFlows] = useState<AdmissionFlow[]>([]);
  const [loadingData, setLoadingData] = useState(false);

  const [employerId, setEmployerId] = useState("");

  // Step 2
  const [admissionStart, setAdmissionStart] = useState(1);
  const [admissionStartDate, setAdmissionStartDate] = useState<Dayjs | undefined>();
  const [hasEsocial, setHasEsocial] = useState(false);

  const [employee, setEmployee] = useState<Employee>();
  const [finishing, setFinishing] = useState(false);

  const [openEmployeeLimitReachedModal, setOpenEmployeeLimitReachedModal] = useState(false);
  const [amountLimitAdmissionModal, setAmountLimitAdmissionModal] = useState<AdmissionsLimitModalData>({
    open: false,
    admissionsLimit: 0,
    isCustomer: false,
    isAccountingCustomer: false,
  });

  const { show } = useIntercom();

  const filteredDepartments = useMemo(
    () =>
      employerId
        ? departments.filter(
            (department) =>
              department.employers?.find((employerDepartment) => employerDepartment.employerId === employerId),
          )
        : [],
    [employerId, departments],
  );

  const next = () => setStep((prevStep) => (prevStep === 1 ? 2 : 1));

  async function handleCancel() {
    onCancel();
    setStep(1);
    form.resetFields();
    setEmployerId("");
    setAdmissionStart(1);
    setAdmissionStartDate(undefined);
    setHasEsocial(false);
  }

  async function handleOk() {
    switch (step) {
      case 1: {
        const validateFields = await form
          .validateFields()
          .then((_) => true)
          .catch((_) => false);
        if (validateFields) {
          setEmployee({ ...form.getFieldsValue() });
          next();
        }
        break;
      }
      case 2: {
        if (employee) {
          onFinish({
            ...employee,
            hasEsocial,
            admissionStartDate: admissionStartDate?.format("YYYY-MM-DD"),
          });
        }
        break;
      }
    }
  }

  function onFinish(employeeForm: Employee) {
    setFinishing(true);
    EmployeeApi.create(employeeForm)
      .then((_) => {
        setFinishing(false);
        IntercomApi.update(INTERCOM_FIELDS.AD_ADDED_EMPLOYEE_SYSTEM);
        messageApi.success(i18n(dictionary.components.newAdmissionModal.notifications.success));
        if (reloadAdmissions) reloadAdmissions();
        handleCancel();
      })
      .catch((error) => {
        setFinishing(false);
        messageApi.error(i18n(dictionary.components.newAdmissionModal.notifications.error));
        if (error.code === 403 && error.message === "ADMISSIONS_LIMIT_REACHED") {
          if (error.data.isAccountingCustomer) {
            setOpenEmployeeLimitReachedModal(true);
          } else {
            setAmountLimitAdmissionModal({
              open: true,
              admissionsLimit: error.data.admissionsLimit,
              isCustomer: error.data.isCustomer,
              isAccountingCustomer: error.data.isAccountingCustomer,
            });
          }
        } else if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.CREATE_EMPLOYEE_ERROR.title),
            message: i18n(dictionary.error.CREATE_EMPLOYEE_ERROR.message),
            onOk: () => onFinish(employeeForm),
            onClickLink: () => show(),
          });
        }
      });
  }

  const insertDepartment = (department: string, employerId: string) => {
    DepartmentApi.create({ id: 0, description: department, employerId })
      .then((response) => {
        messageApi.success(i18n(dictionary.components.newAdmissionModal.steps[1].notifications.department.success));
        setDepartments([...departments, response]);
      })
      .catch((_) =>
        messageApi.error(i18n(dictionary.components.newAdmissionModal.steps[1].notifications.department.error)),
      );
  };

  async function getInitData() {
    if (open) {
      // Campos que precisam ser iniciados
      form.setFieldValue("workloadType", 1);

      setLoadingData(true);
      // Vínculos empregatícios
      if (!employmentRelationships.length) {
        await EmploymentRelationshipApi.fetch().then((res: EmploymentRelationship[]) =>
          setEmploymentRelationships(res),
        );
      }
      // Empregadores
      if (!employers.length) {
        await EmployerApi.fetch().then((res: Employer[]) => setEmployers(res));
      }
      // Departamentos
      if (!departments.length) {
        await DepartmentApi.fetch().then((res: Department[]) => setDepartments(res));
      }
      // Fluxos de admissões
      if (!admissionFlows.length) {
        await AdmissionFlowApi.fetchAdmissionFlows().then((res: AdmissionFlow[]) => setAdmissionFlows(res));
      }
      setLoadingData(false);
    }
  }

  useEffect(() => {
    getInitData();
  }, [open]);

  return (
    <>
      {messageContextHolder}
      <EmployeeLimitReachedModal
        open={openEmployeeLimitReachedModal}
        cancel={() => setOpenEmployeeLimitReachedModal(false)}
      />
      <AdmissionsLimitModal
        data={amountLimitAdmissionModal}
        handleCancel={() =>
          setAmountLimitAdmissionModal({
            open: false,
            admissionsLimit: 0,
            isCustomer: false,
            isAccountingCustomer: false,
          })
        }
      />
      <Modal
        centered
        width={1000}
        styles={{ content: { margin: 0, padding: 0 } }}
        open={open}
        onCancel={handleCancel}
        footer={false}
        maskClosable={false}
      >
        <Row style={{ minHeight: 600 }}>
          <Col span={7}>
            <Flex
              vertical
              style={{ padding: 24 }}
            >
              <Flex
                style={{ marginBottom: 24 }}
                align="flex-end"
              >
                <Image
                  src={LogoColoridaSimples}
                  preview={false}
                  width={80}
                />
                <Title
                  style={titleStyle}
                  level={5}
                >
                  {i18n(dictionary.components.newAdmissionModal.name)}
                </Title>
              </Flex>
              <Steps
                direction="vertical"
                current={step - 1}
                items={[
                  {
                    title: `1. ${i18n(dictionary.components.newAdmissionModal.steps[1].title)}`,
                    description: i18n(dictionary.components.newAdmissionModal.steps[1].description),
                    icon: <SolutionOutlined />,
                  },
                  {
                    title: `2. ${i18n(dictionary.components.newAdmissionModal.steps[2].title)}`,
                    description: i18n(dictionary.components.newAdmissionModal.steps[2].description),
                    icon: <ScheduleOutlined />,
                  },
                ]}
              />
            </Flex>
            <Flex
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
              }}
            >
              <Image
                style={{
                  borderBottomLeftRadius: 16,
                }}
                src={FirstAccessFlowDocumentation}
                preview={false}
              />
            </Flex>
          </Col>
          <Col
            span={17}
            style={{ background: "#F5F7FA", borderTopRightRadius: 16, borderBottomRightRadius: 16 }}
          >
            <Flex
              vertical
              style={{ padding: 24 }}
            >
              <Title
                style={titleStyle}
                level={3}
              >
                {i18n(dictionary.components.newAdmissionModal.title)}
              </Title>
              <Flex
                vertical
                style={{ padding: 24 }}
              >
                <Title
                  style={{ fontWeight: 600 }}
                  type="secondary"
                  level={5}
                >
                  {`${i18n(dictionary.components.newAdmissionModal.currentStep)}${step} / 2`}
                </Title>
                <Title
                  style={titleStyle}
                  level={4}
                >
                  {i18n(dictionary.components.newAdmissionModal.steps[step].title)}
                </Title>
                <Flex style={{ margin: "24px 0" }}>
                  {/* Passo 1 */}
                  {step === 1 && (
                    <Form
                      form={form}
                      style={{ width: "100%" }}
                      labelCol={{
                        sm: { span: 6 },
                      }}
                      wrapperCol={{
                        sm: { span: 16 },
                      }}
                      colon={false}
                    >
                      {/* Nome completo */}
                      <Item
                        style={{ marginBottom: 24 }}
                        label={i18n(dictionary.components.newAdmissionModal.steps[1].form.name)}
                        name="name"
                        rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                      >
                        <Input />
                      </Item>
                      {/* Vínculo empregatício */}
                      <Item
                        style={{ marginBottom: 24 }}
                        label={i18n(dictionary.components.newAdmissionModal.steps[1].form.employmentRelationship)}
                        name="employmentRelationshipId"
                        rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                      >
                        <Select
                          options={employmentRelationships.map((element) => ({
                            value: element.id,
                            label: element.description,
                          }))}
                          loading={loadingData}
                          disabled={loadingData}
                        />
                      </Item>
                      {/* Cargo */}
                      <Item
                        style={{ marginBottom: 24 }}
                        label={i18n(dictionary.components.newAdmissionModal.steps[1].form.jobOccupation)}
                        name="jobOccupation"
                        rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                      >
                        <Input />
                      </Item>
                      {/* Salário */}
                      <Item
                        style={{ marginBottom: 24 }}
                        label={i18n(dictionary.components.newAdmissionModal.steps[1].form.salary)}
                        name="salary"
                      >
                        <NumberFormatBase
                          customInput={Input}
                          defaultValue="000"
                          format={formatToBRL}
                        />
                      </Item>
                      {/* E-mail pessoal */}
                      <Item
                        style={{ marginBottom: 24 }}
                        label={i18n(dictionary.components.newAdmissionModal.steps[1].form.email)}
                        name="email"
                        rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                      >
                        <Input />
                      </Item>
                      {/* Empregador */}
                      <Item
                        style={{ marginBottom: 24 }}
                        label={i18n(dictionary.components.newAdmissionModal.steps[1].form.employer)}
                        name="employerId"
                        rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                      >
                        <Select
                          onChange={(value: string) => {
                            setEmployerId(value);
                            form.setFieldValue("departmentId", undefined);
                          }}
                          options={employers.map((element) => ({
                            value: element.id,
                            label: element.fullName,
                          }))}
                          loading={loadingData}
                          disabled={loadingData}
                        />
                      </Item>
                      {/* Departamento */}
                      {employerId && (
                        <Item
                          style={{ marginBottom: 24 }}
                          label={i18n(dictionary.components.newAdmissionModal.steps[1].form.department)}
                          name="departmentId"
                        >
                          <SelectInsert
                            onInsert={(value) => insertDepartment(value, employerId)}
                            loading={loadingData}
                            disabled={loadingData}
                          >
                            {filteredDepartments.map((department) => (
                              <Option
                                key={department.id}
                                title={department.description}
                                value={department.id}
                              >
                                {department.description}
                              </Option>
                            ))}
                          </SelectInsert>
                        </Item>
                      )}
                      {/* Carga horária */}
                      <Item
                        style={{ marginBottom: 24 }}
                        label={i18n(dictionary.components.newAdmissionModal.steps[1].form.workload)}
                        name="workload"
                      >
                        <InputNumber
                          min={0}
                          style={{ width: "100%" }}
                          controls={inputNumberControls}
                          addonAfter={
                            <Item
                              name="workloadType"
                              noStyle
                            >
                              <Select
                                defaultValue={1}
                                options={[
                                  {
                                    value: 1,
                                    label: i18n(
                                      dictionary.components.newAdmissionModal.steps[1].form.workloadType.weekly,
                                    ),
                                  },
                                  {
                                    value: 2,
                                    label: i18n(
                                      dictionary.components.newAdmissionModal.steps[1].form.workloadType.monthly,
                                    ),
                                  },
                                ]}
                              />
                            </Item>
                          }
                        />
                      </Item>
                      {/* Período de experiência */}
                      <Item
                        style={{ marginBottom: 24 }}
                        label={i18n(dictionary.components.newAdmissionModal.steps[1].form.workExperiencePeriod)}
                        name="workExperiencePeriodInDays"
                      >
                        <InputNumber
                          min={0}
                          style={{ width: "100%" }}
                          addonAfter={i18n(dictionary.label.days)}
                          controls={inputNumberControls}
                        />
                      </Item>
                      {/* Fluxo de admissão */}
                      <Item
                        style={{ marginBottom: 24 }}
                        label={i18n(dictionary.components.newAdmissionModal.steps[1].form.admissionFlow)}
                        name="admissionFlowId"
                        rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                      >
                        <Select
                          options={admissionFlows.map((element) => ({
                            value: element.id,
                            label: element.name,
                          }))}
                          loading={loadingData}
                          disabled={loadingData}
                        />
                      </Item>
                      {/* Data de admissão */}
                      <Item
                        label={i18n(dictionary.components.newAdmissionModal.steps[1].form.admissionDate)}
                        name="admissionDate"
                      >
                        <DatePicker
                          placeholder=""
                          style={{ width: "100%" }}
                          format={"DD/MM/YYYY"}
                        />
                      </Item>
                    </Form>
                  )}

                  {/* Passo 2 */}
                  {step === 2 && (
                    <Form layout="vertical">
                      {/* Início do processo de admissão */}
                      <Item
                        label={
                          <>
                            <span>
                              {i18n(dictionary.components.newAdmissionModal.steps[2].form.startAdmissionProcess.label)}
                            </span>
                            &nbsp;
                            <Tooltip
                              title={i18n(
                                dictionary.components.newAdmissionModal.steps[2].form.startAdmissionProcess.tooltip,
                              )}
                            >
                              <InfoCircleOutlined />
                            </Tooltip>
                          </>
                        }
                      >
                        <Radio.Group
                          value={admissionStart}
                          onChange={({ target }) => {
                            const { value } = target;
                            setAdmissionStartDate(value === 1 ? undefined : dayjs().add(1, "day"));
                            setAdmissionStart(value);
                          }}
                        >
                          <Flex
                            vertical
                            gap={5}
                          >
                            <Radio value={1}>
                              {i18n(
                                dictionary.components.newAdmissionModal.steps[2].form.startAdmissionProcess.options[1],
                              )}
                            </Radio>
                            <Radio value={2}>
                              {i18n(
                                dictionary.components.newAdmissionModal.steps[2].form.startAdmissionProcess.options[2],
                              )}
                            </Radio>
                          </Flex>
                        </Radio.Group>
                      </Item>
                      {admissionStart === 2 && (
                        <Item>
                          <Calendar
                            disabledDate={(currentDate) => dayjs() > currentDate}
                            value={admissionStartDate}
                            onChange={(value) => setAdmissionStartDate(value)}
                            style={{ width: 300 }}
                            fullscreen={false}
                          />
                        </Item>
                      )}
                      {/* Enviar admissão para o E-Social? */}
                      <Item label={i18n(dictionary.components.newAdmissionModal.steps[2].form.sendToEsocial)}>
                        <Switch
                          value={hasEsocial}
                          onChange={(value) => setHasEsocial(value)}
                        />
                      </Item>
                    </Form>
                  )}
                </Flex>
              </Flex>
              {/* Botões de ação */}
              <Flex gap={10}>
                {step === 2 && (
                  <Button
                    icon={<LeftOutlined />}
                    onClick={() => setStep(1)}
                  >
                    {i18n(dictionary.action.back)}
                  </Button>
                )}
                <Button
                  type="primary"
                  icon={<RightOutlined />}
                  onClick={handleOk}
                  loading={finishing}
                >
                  {i18n(dictionary.components.newAdmissionModal.steps[step].button)}
                </Button>
              </Flex>
            </Flex>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
