import { FrownOutlined } from "@ant-design/icons";
import { Flex, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { Routes } from "src/routes/routing";
import COLOR from "src/styles/colors";

const { Title, Text } = Typography;

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <Flex
      vertical
      align="center"
      justify="center"
      style={{ height: "100%" }}
      gap={10}
    >
      <Title
        level={1}
        style={{ margin: 0, color: COLOR.PURPLE, fontSize: "50pt" }}
      >
        <FrownOutlined />
      </Title>
      <Title
        level={2}
        style={{ margin: 0 }}
      >
        Ops...
      </Title>
      <Flex
        vertical
        align="center"
      >
        <Text>A página que você está procurando não existe!</Text>
        <Text>
          <span
            style={{ fontWeight: 600, color: COLOR.PURPLE, cursor: "pointer" }}
            onClick={() => navigate(Routes.HOME)}
          >
            Clique aqui
          </span>
          &nbsp;para voltar para a home.
        </Text>
      </Flex>
    </Flex>
  );
}
