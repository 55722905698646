import { Button, Space } from "antd";
import { IntercomApi } from "src/api";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "src/assets/icons";
import { Card, Table } from "src/components";
import CustomBreadcrumb from "src/components/CustomBreadcrumb/CustomBreadcrumb";
import { context, dictionary, i18n } from "src/utils";
import { INTERCOM_FIELDS } from "src/utils/enums/intercom.enum";

const { Column } = Table;

type Props = {
  employmentRelationships: EmploymentRelationship[];
  onCreateDocumentation(): void;
  onDeleteDocumentation(id: number): void;
  onEditDocumentation(id: number): void;
};

function Documentation({
  employmentRelationships: employerRelationships,
  onCreateDocumentation,
  onDeleteDocumentation,
  onEditDocumentation,
}: Props): JSX.Element {
  return (
    <Card
      title={
        <CustomBreadcrumb
          root={i18n(dictionary.menu.registration)}
          title={i18n(dictionary.menu.employmentRelationship)}
        />
      }
      actions={
        <Button
          className="full-size-mobile"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => onCreateDocumentation()}
        >
          {i18n(dictionary.action.register_new, {
            context: context.male,
            item: i18n(dictionary.entity.employment_relationship),
          })}
        </Button>
      }
    >
      <Table
        scroll={{ x: true }}
        dataSource={employerRelationships}
      >
        <Column<EmploymentRelationship>
          title={i18n(dictionary.label.description)}
          dataIndex="description"
          sorter={(a, b) => a.description.localeCompare(b.description)}
        />
        <Column<EmploymentRelationship>
          width={20}
          title={i18n(dictionary.label.action)}
          render={(_, record) => (
            <Space direction="horizontal">
              <Button
                type="default"
                icon={<EditOutlined />}
                onClick={() => {
                  IntercomApi.update(INTERCOM_FIELDS.AD_EDIT_EMPLOYEE_EMPLOYMENT_LINK_CLICKED);
                  onEditDocumentation(record.id);
                }}
              >
                {i18n(dictionary.action.edit)}
              </Button>
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => onDeleteDocumentation(record.id)}
              >
                {i18n(dictionary.action.delete)}
              </Button>
            </Space>
          )}
        />
      </Table>
    </Card>
  );
}

export default Documentation;
