import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Image, Modal, notification, Row, Space, Spin, Typography, Upload, UploadFile } from "antd";
import { Buffer } from "buffer";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdmissionFlowApi, EmployeeApi } from "src/api";
import { CurrentLogo } from "src/assets/images";
import AdmissionFlowStepHalfScreen from "src/components/AdmissionFlowStepHalfScreen";
import PublicEmployeeStartPage from "src/components/PublicEmployeeStartPage";
import { REACT_APP_BASE_PATH } from "src/constants";
import { dictionary, getDescryptedParamsData, i18n } from "src/utils";
import styled from "styled-components";

const { Title } = Typography;

const STEP_ID = 5;

const StyledTitle = styled(Title)`
  &.ant-typography {
    color: ${(props) => props.color};
  }
`;

const rowStyle: React.CSSProperties = {
  height: "100vh",
};

export default function AdmissionExam() {
  const params = useParams();

  const [notificationApi, contextHolder] = notification.useNotification();

  const [step, setStep] = useState(0);

  const [employeeId, setEmployeeId] = useState("");

  const [data, setData] = useState({
    primaryColor: "#884ea6",
    secondaryColor: "#66C790",
    logo: "",
    pagePhrase: "",
    pageDescription: "",
  });

  const [attachment, setAttachment] = useState<UploadFile<File> | null>(null);
  const [sending, setSending] = useState(false);

  const [sended, setSended] = useState(false);

  async function sendAttachment() {
    setSending(true);
    const fileBuffer = await attachment?.originFileObj
      ?.arrayBuffer()
      .then((response) => Buffer.from(response))
      .catch((_) => null);
    if (fileBuffer) {
      const response = await EmployeeApi.sendAdmissionExam(parseInt(employeeId), fileBuffer);
      if (response) {
        notificationApi.success({
          message: i18n(dictionary.alright),
          description: i18n(dictionary.pages.admissionExam.notifications.success),
        });
        setSended(true);
      } else {
        notificationApi.error({
          message: i18n(dictionary.ops),
          description: i18n(dictionary.pages.admissionExam.notifications.error),
        });
      }
    }
    setSending(false);
  }

  async function getData(employeeId: string) {
    const responseData = await AdmissionFlowApi.findByEmployeeId(employeeId);
    if (responseData) {
      const stepData = responseData.admissionFlow.flowSteps.find(
        ({ stepId }: { stepId: number }) => stepId === STEP_ID,
      );
      setEmployeeId(employeeId);
      setData({
        primaryColor: responseData.admissionFlow.primaryColor,
        secondaryColor: responseData.admissionFlow.secondaryColor,
        logo: responseData.admissionFlow.logo,
        pagePhrase: stepData.pagePhrase,
        pageDescription: stepData.pageDescription,
      });
      setSended(!!responseData.admissionExamFileId);
      setStep(1);
    }
  }

  useEffect(() => {
    const paramsData = getDescryptedParamsData(params.data || "");
    if (paramsData && paramsData.employeeId) {
      getData(paramsData.employeeId);
    }
  }, []);

  return (
    <>
      <Modal
        centered
        closable={false}
        footer={false}
        open={sended}
        title={i18n(dictionary.pages.admissionExam.modal.title)}
      >
        {i18n(dictionary.pages.admissionExam.modal.content)}
      </Modal>
      {contextHolder}
      {step === 0 && (
        <Flex
          style={{ height: "100vh", width: "100vw" }}
          align="center"
          justify="center"
        >
          <Spin size="large" />
        </Flex>
      )}
      {step === 1 && (
        <PublicEmployeeStartPage
          next={() => setStep((prevStep) => prevStep + 1)}
          data={data}
        />
      )}
      {step === 2 && (
        <Row style={rowStyle}>
          <AdmissionFlowStepHalfScreen
            primaryColor={data.primaryColor}
            pagePhrase={data.pagePhrase}
          />
          <Col
            span={16}
            style={{ background: "#F5F7FA" }}
          >
            <Space
              style={{
                width: "100%",
                justifyContent: "end",
                marginBottom: "20%",
                textAlign: "end",
              }}
            >
              <Image
                style={{ marginTop: "48px", marginRight: "48px", height: "100px", width: "100px" }}
                src={!data.logo ? CurrentLogo : `${REACT_APP_BASE_PATH}/download/${data.logo}`}
                alt="Logo"
                preview={false}
              />
            </Space>
            <Flex
              style={{ marginBottom: 20 }}
              vertical
              align="center"
              justify="center"
            >
              <StyledTitle style={{ marginBottom: 50, color: "gray" }}>
                {i18n(dictionary.pages.admissionExam.title)}
              </StyledTitle>
              <Flex
                vertical
                align="center"
                justify="center"
                gap={25}
              >
                <Upload
                  name="file"
                  accept=".pdf"
                  multiple={false}
                  maxCount={1}
                  fileList={attachment ? [attachment] : []}
                  beforeUpload={() => false}
                  onChange={({ fileList }) => setAttachment(fileList[0])}
                  showUploadList={{ showRemoveIcon: false }}
                >
                  <Button
                    size="large"
                    type="primary"
                    icon={<UploadOutlined />}
                  >
                    {i18n(dictionary.pages.admissionExam.upload)}
                  </Button>
                </Upload>
                <Button
                  disabled={!attachment}
                  onClick={sendAttachment}
                  loading={sending}
                >
                  {i18n(dictionary.pages.admissionExam.send)}
                </Button>
              </Flex>
            </Flex>
          </Col>
        </Row>
      )}
    </>
  );
}
