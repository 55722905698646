import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "../request";

export const fetch = async (): Promise<Contract[]> => {
  try {
    const { data } = await request.get("/contracts");
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const fetchById = async (id: number): Promise<Contract> => {
  try {
    const { data } = await request.get(`/contracts/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const updateById = async (id: number, update: Contract) => {
  try {
    const { data } = await request.put(`/contracts/${id}`, update);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const create = async (create: Contract) => {
  try {
    const { data } = await request.post(`/contracts`, create);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const destroy = async (id: number) => {
  try {
    const { data } = await request.delete(`/contracts/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const getFinalDocument = async (id: number, employeeId: number): Promise<string> => {
  try {
    const { data } = await request.get(`/contracts/get-final/${id}/${employeeId}`);
    return data.finalText;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};
