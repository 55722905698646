import { Col, Image, Row, Typography } from "antd";
import { SendingDocument } from "src/assets/images";
import styled from "styled-components";

const { Title } = Typography;

const contentTop: React.CSSProperties = {
  height: "50vh",
  padding: "20px 0px",
  justifyContent: "center",
};

const contentBottom: React.CSSProperties = {
  margin: "64px 32px",
};

const StyledTitle = styled(Title)`
  &.ant-typography {
    color: ${(props) => props.color};
  }
`;

type AdmissionFlowStepHalfScreenProps = {
  primaryColor: string;
  pagePhrase: string;
};

export default function AdmissionFlowStepHalfScreen({ primaryColor, pagePhrase }: AdmissionFlowStepHalfScreenProps) {
  const stepsStyle: React.CSSProperties = {
    color: "#fff",
    width: "50%",
    backgroundColor: primaryColor,
  };

  return (
    <Col
      xs={24}
      sm={8}
      md={8}
      lg={8}
      xl={8}
      xxl={8}
      style={stepsStyle}
    >
      <Row style={contentTop}>
        <Image
          width="auto"
          height="100%"
          src={SendingDocument}
          alt="SendingDocumentStart"
          preview={false}
        />
      </Row>
      <Row style={contentBottom}>
        <StyledTitle color="white">{pagePhrase}</StyledTitle>
      </Row>
    </Col>
  );
}
