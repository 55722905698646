import { CheckOutlined, SignatureOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Flex, Image, Row, Space, Spin, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdmissionFlowApi, ContractApi, EmployeeApi } from "src/api";
import { CurrentLogo } from "src/assets/images";
import AdmissionFlowStepHalfScreen from "src/components/AdmissionFlowStepHalfScreen";
import PublicEmployeeStartPage from "src/components/PublicEmployeeStartPage";
import RichTextEditor from "src/components/RichTextEditor/RichTextEditor";
import { REACT_APP_BASE_PATH } from "src/constants";
import { dictionary, getDescryptedParamsData, i18n } from "src/utils";
import styled from "styled-components";

const { Title, Text } = Typography;

const StyledTitle = styled(Title)`
  &.ant-typography {
    color: ${(props) => props.color};
  }
`;

const rowStyle: React.CSSProperties = {
  height: "100vh",
};

type ViewSignDocumentProps = {
  id: 2 | 4 | 6;
};

export default function ViewSignDocument({ id }: ViewSignDocumentProps) {
  const [messageApi, contextHolder] = message.useMessage();

  const [step, setStep] = useState(0);

  const [employeeId, setEmployeeId] = useState("");

  const [data, setData] = useState({
    primaryColor: "#884ea6",
    secondaryColor: "#66C790",
    logo: "",
    pagePhrase: "",
    pageDescription: "",
    document: JSON.stringify([
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ]),
    validationType: 0,
  });

  const [acceptedTerm, setAcceptedTerm] = useState(false);

  const [creating, setCreating] = useState(false);
  const [loading, setLoading] = useState(false);

  const [signedDocument, setSignedDocument] = useState(false);

  const [autentiqueResponse, setAutentiqueResponse] = useState({
    documentId: "",
    employeeSignLink: "",
  });

  const params = useParams();

  async function getData(employeeId: string) {
    const responseData = await AdmissionFlowApi.findByEmployeeId(employeeId);
    if (responseData) {
      setSignedDocument(responseData[`${getDocumentName(id)}Signed`]);
      const stepData = responseData.admissionFlow.flowSteps.find(({ stepId }: { stepId: number }) => stepId === id);
      const document = await ContractApi.getFinalDocument(stepData.documentId, parseInt(employeeId));
      setEmployeeId(employeeId);
      setData({
        primaryColor: responseData.admissionFlow.primaryColor,
        secondaryColor: responseData.admissionFlow.secondaryColor,
        logo: responseData.admissionFlow.logo,
        pagePhrase: stepData.pagePhrase,
        pageDescription: stepData.pageDescription,
        document,
        validationType: stepData.validationType,
      });
      if (stepData.validationType === 2) {
        setAutentiqueResponse((prevData) => ({
          ...prevData,
          documentId: responseData[`${getDocumentName(id)}FileId`],
        }));
      }
      setStep(1);
    }
  }

  useEffect(() => {
    const paramsData = getDescryptedParamsData(params.data || "");
    if (paramsData && paramsData.employeeId) {
      getData(paramsData.employeeId);
    }
  }, []);

  async function handleOk() {
    setLoading(true);
    const documentName = getDocumentName(id);
    if (documentName) {
      const response = await EmployeeApi.signDocument(
        parseInt(employeeId),
        data.validationType,
        documentName,
        autentiqueResponse.documentId,
      );
      if (response.success) {
        messageApi.success(i18n(dictionary.pages.viewSignDocument.notifications.success));
        setSignedDocument(true);
        if (data.validationType === 2) setStep(2);
      } else if (response.message) {
        messageApi.error(i18n(dictionary.pages.viewSignDocument.notifications[response.message as "unsigned"]));
      } else {
        messageApi.error(i18n(dictionary.pages.viewSignDocument.notifications.error));
      }
    }
    setLoading(false);
  }

  async function createDocument() {
    setCreating(true);
    const documentName = getDocumentName(id);
    if (documentName) {
      const response = await EmployeeApi.createDocument(parseInt(employeeId), data.document, id, documentName);
      setAutentiqueResponse({
        documentId: response.id,
        employeeSignLink: response.employeeLink,
      });
      setStep(3);
    }
    setCreating(false);
  }

  return (
    <>
      {contextHolder}
      {step === 0 && (
        <Flex
          style={{ height: "100vh", width: "100vw" }}
          align="center"
          justify="center"
        >
          <Spin size="large" />
        </Flex>
      )}
      {step === 1 && (
        <PublicEmployeeStartPage
          next={() => setStep((prevStep) => prevStep + 1)}
          data={data}
        />
      )}
      {[2, 3].includes(step) && (
        <Row style={rowStyle}>
          <AdmissionFlowStepHalfScreen
            primaryColor={data.primaryColor}
            pagePhrase={data.pagePhrase}
          />
          <Col
            xs={24}
            sm={16}
            md={16}
            lg={16}
            xl={16}
            xxl={16}
            style={{ background: "#F5F7FA" }}
          >
            <Space
              style={{
                width: "100%",
                justifyContent: "end",
                marginBottom: 30,
                textAlign: "end",
              }}
            >
              <Image
                style={{ marginTop: "48px", marginRight: "48px", height: "100px", width: "100px" }}
                src={!data.logo ? CurrentLogo : `${REACT_APP_BASE_PATH}/download/${data.logo}`}
                alt="Logo"
                preview={false}
              />
            </Space>
            {step === 2 ? (
              <Flex
                style={{ marginBottom: 20, width: "100%", padding: "0 8%" }}
                vertical
              >
                <StyledTitle color="gray">{i18n(dictionary.pages.viewSignDocument.title[id])}</StyledTitle>
                <Flex
                  style={{ marginBottom: 20, width: "100%" }}
                  vertical
                  align="center"
                  justify="center"
                  gap={10}
                >
                  <Card style={{ width: "100%" }}>
                    <RichTextEditor
                      content={JSON.parse(data.document)}
                      style={{
                        height: 290,
                      }}
                      readOnly
                    />
                  </Card>
                </Flex>
                {signedDocument ? (
                  <Flex justify="center">
                    <Text type="warning">{i18n(dictionary.pages.viewSignDocument.signedDocument)}</Text>
                  </Flex>
                ) : (
                  <>
                    {data.validationType === 1 ? (
                      <Flex
                        vertical
                        align="center"
                        justify="center"
                        gap={20}
                      >
                        <Checkbox
                          checked={acceptedTerm}
                          onChange={({ target }) => setAcceptedTerm(target.checked)}
                        >
                          {i18n(dictionary.pages.viewSignDocument.term[id])}
                        </Checkbox>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="large"
                          onClick={handleOk}
                          disabled={!acceptedTerm}
                          loading={loading}
                        >
                          {i18n(dictionary.pages.viewSignDocument.confirm)}
                        </Button>
                      </Flex>
                    ) : (
                      <Flex
                        style={{ width: "100%" }}
                        justify="center"
                      >
                        <Button
                          icon={<SignatureOutlined />}
                          onClick={createDocument}
                          loading={creating}
                        >
                          {i18n(dictionary.pages.viewSignDocument.sign)}
                        </Button>
                      </Flex>
                    )}
                  </>
                )}
              </Flex>
            ) : (
              <Flex
                style={{ marginBottom: 20, width: "100%", padding: "0 8%" }}
                vertical
              >
                <StyledTitle color="gray">{i18n(dictionary.pages.viewSignDocument.title[id])}</StyledTitle>
                <Flex
                  style={{ marginBottom: 20, width: "100%" }}
                  vertical
                  align="center"
                  justify="center"
                  gap={10}
                >
                  <Card style={{ width: "100%" }}>
                    <iframe
                      style={{
                        width: "100%",
                        height: 300,
                        border: "none",
                        boxShadow: "rgb(208, 208, 208) 0px 0px 8px",
                      }}
                      src={autentiqueResponse.employeeSignLink}
                    />
                  </Card>
                </Flex>
                <Flex
                  style={{ width: "100%" }}
                  justify="center"
                >
                  <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    onClick={handleOk}
                    loading={loading}
                  >
                    {i18n(dictionary.pages.viewSignDocument.confirmSignature)}
                  </Button>
                </Flex>
              </Flex>
            )}
          </Col>
        </Row>
      )}
    </>
  );
}

function getDocumentName(step: Step) {
  return (
    [
      { id: 2, column: "workContract" },
      { id: 4, column: "workProposal" },
      { id: 6, column: "responsibilityTerm" },
    ].find((element) => element.id === step)?.column ?? ""
  );
}
