import {
  BookOutlined,
  DollarCircleOutlined,
  FileOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuOutlined,
  NotificationOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useStores } from "src/hooks";
import { Routes } from "src/routes";
import { dictionary, i18n } from "src/utils";

export default function MobileMenu() {
  const navigate = useNavigate();

  const { auth } = useStores();
  const { shutdown, boot } = useIntercom();

  function onClick({ key }: { key: string }) {
    navigate(key);
  }

  const onSignOut = async (): Promise<void> => {
    await auth.logout();
    shutdown();
    boot();
    navigate(Routes.LOGIN);
  };

  return (
    <Dropdown
      className="mobile-menu"
      placement="bottomRight"
      menu={{
        items: [
          { key: Routes.HOME, label: i18n(dictionary.menu.home), icon: <HomeOutlined />, onClick },
          {
            key: Routes.REGISTRATION,
            label: i18n(dictionary.menu.registration),
            icon: <BookOutlined />,
            children: [
              { key: Routes.EMPLOYER, label: i18n(dictionary.menu.employer), onClick },
              { key: Routes.EMPLOYEE, label: i18n(dictionary.menu.employee), onClick },
              { key: Routes.CONTRACT, label: i18n(dictionary.menu.contract), onClick },
              { key: Routes.ADMISSION_FLOW, label: i18n(dictionary.menu.admissionFlow), onClick },
              { key: Routes.DOCUMENTATION, label: i18n(dictionary.menu.employmentRelationship), onClick },
            ],
          },
          {
            key: Routes.REPORT,
            label: i18n(dictionary.menu.report),
            icon: <FileOutlined />,
            children: [
              { key: Routes.REPORT_REGISTRATION_FORM, label: i18n(dictionary.menu.registrationForm), onClick },
            ],
          },
          {
            key: Routes.FINANCE,
            label: i18n(dictionary.menu.finance),
            icon: <DollarCircleOutlined />,
            onClick,
          },
          {
            key: Routes.SETTINGS,
            label: i18n(dictionary.menu.setting),
            icon: <SettingOutlined />,
            onClick,
          },
          {
            key: Routes.NEWS,
            label: i18n(dictionary.menu.new),
            icon: <NotificationOutlined />,
            onClick,
          },
          {
            key: "quit",
            label: i18n(dictionary.menu.exit),
            icon: <LogoutOutlined />,
            style: { color: "#ff4d4f" },
            onClick: onSignOut,
          },
        ],
      }}
    >
      <Button
        shape="circle"
        icon={<MenuOutlined />}
      />
    </Dropdown>
  );
}
