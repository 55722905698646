import { Form, Input, Modal, message } from "antd";
import { useState } from "react";
import { Descendant } from "slate";
import { ContractApi } from "src/api";
import { CONTRACT_TEXT_FIELD, dictionary, i18n } from "src/utils";
import RichTextEditor from "../RichTextEditor/RichTextEditor";

const { Item } = Form;

type CreateDocumentModalProps = {
  open: boolean;
  onCancel: () => void;
  setDocuments?: React.Dispatch<React.SetStateAction<Contract[]>>;
  setData?: React.Dispatch<React.SetStateAction<AdmissionFlowStepPayload>>;
};

export default function CreateDocumentModal({ open, onCancel, setDocuments, setData }: CreateDocumentModalProps) {
  const [messageApi, contextHolder] = message.useMessage();

  const [creating, setCreating] = useState(false);

  const [description, setDescription] = useState("");
  const [content, setContent] = useState<Descendant[]>([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);

  async function onOk() {
    setCreating(true);
    const response = await ContractApi.create({
      id: 0,
      description,
      text: JSON.stringify(content),
    });
    if (response) {
      messageApi.success(i18n(dictionary.components.createDocumentModal.notifications.success));

      // Atualiza a lista de documentos;
      if (!!setDocuments)
        setDocuments((prevDocuments) => [
          ...prevDocuments,
          { id: response.id, description: response.description, text: response.text },
        ]);
      // Atualiza o documento do passo do fluxo de admissão atual;
      if (!!setData) setData((prevData) => ({ ...prevData, documentId: response.id }));

      onCancel();
    } else {
      messageApi.error(i18n(dictionary.components.createDocumentModal.notifications.error));
    }
    setCreating(false);
  }

  return (
    <>
      {contextHolder}
      <Modal
        centered
        width={800}
        title={i18n(dictionary.components.createDocumentModal.title)}
        open={open}
        onCancel={onCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={onOk}
        okButtonProps={{ disabled: !description, loading: creating }}
        okText={i18n(dictionary.components.createDocumentModal.footer.ok)}
      >
        <Form
          style={{ marginTop: 20 }}
          layout="vertical"
        >
          {/* Descrição */}
          <Item
            label={i18n(dictionary.components.createDocumentModal.fields.description)}
            required
          >
            <Input
              value={description}
              onChange={({ target }) => setDescription(target.value)}
            />
          </Item>
          {/* Conteúdo */}
          <Item label={i18n(dictionary.components.createDocumentModal.fields.content)}>
            <RichTextEditor
              onChange={setContent}
              content={content}
              fieldList={fieldList}
              style={{
                height: 290,
              }}
              readOnly={false}
            />
          </Item>
        </Form>
      </Modal>
    </>
  );
}

const fieldList: CONTRACT_TEXT_FIELD[] = [
  CONTRACT_TEXT_FIELD.EMPLOYER_NAME,
  CONTRACT_TEXT_FIELD.EMPLOYER_IDENTIFICATION,
  CONTRACT_TEXT_FIELD.EMPLOYER_ADDRESS_STREET,
  CONTRACT_TEXT_FIELD.EMPLOYER_ADDRESS_NUMBER,
  CONTRACT_TEXT_FIELD.EMPLOYER_ADDRESS_DISTRICT,
  CONTRACT_TEXT_FIELD.EMPLOYER_ADDRESS_ZIP_CODE,
  CONTRACT_TEXT_FIELD.EMPLOYEE_NAME,
  CONTRACT_TEXT_FIELD.EMPLOYEE_NATIONALITY,
  CONTRACT_TEXT_FIELD.EMPLOYEE_MARITAL_STATUS,
  CONTRACT_TEXT_FIELD.EMPLOYEE_OCCUPATION,
  CONTRACT_TEXT_FIELD.EMPLOYEE_IDENTIFICATION_1,
  CONTRACT_TEXT_FIELD.EMPLOYEE_IDENTIFICATION_2,
  CONTRACT_TEXT_FIELD.EMPLOYEE_ADDRESS_STREET,
  CONTRACT_TEXT_FIELD.EMPLOYEE_ADMISSION_DAY,
  CONTRACT_TEXT_FIELD.EMPLOYEE_ADMISSION_FULL_MONTH,
  CONTRACT_TEXT_FIELD.EMPLOYEE_ADMISSION_YEAR,
  CONTRACT_TEXT_FIELD.EMPLOYER_CITY,
  CONTRACT_TEXT_FIELD.CONTRACT_DATE,
];
