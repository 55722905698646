import {
  AuditOutlined,
  FileDoneOutlined,
  FileExclamationOutlined,
  FileTextOutlined,
  IdcardOutlined,
  QuestionCircleOutlined,
  SmileOutlined,
} from "@ant-design/icons";

export default function AdmissionFlowStepIcon({ stepId }: { stepId: number }) {
  switch (stepId) {
    case 1:
      return <IdcardOutlined />;
    case 2:
      return <AuditOutlined />;
    case 3:
      return <SmileOutlined />;
    case 4:
      return <FileTextOutlined />;
    case 5:
      return <FileDoneOutlined />;
    case 6:
      return <FileExclamationOutlined />;
    default:
      return <QuestionCircleOutlined />;
  }
}
