import React from "react";
import { Outlet, Route, Routes as Switch, useNavigate } from "react-router-dom";
import {
  AdmissionExam,
  AdmissionFlow,
  AdmissionFlowCreateUpdate,
  Contract,
  ContractCreate,
  ContractEdit,
  Dashboard,
  Documentation,
  DocumentationCreate,
  DocumentationEdit,
  Employee,
  EmployeeEdit,
  Employer,
  EmployerForm,
  Home,
  Login,
  NewSendingDocuments,
  RegistrationForm,
  SendingDocuments,
  Settings,
  User,
  UserForm,
} from "src/pages";
import Finance from "src/pages/Private/Finance";
import News from "src/pages/Private/News";
import NotFound from "src/pages/Private/NotFound/NotFound";
import ForgotPassword from "src/pages/Public/ForgotPassword/ForgotPassword";
import ResetPassword from "src/pages/Public/ResetPassword/ResetPassword";
import ViewSignDocument from "src/pages/Public/ViewSignDocument/ViewSignDocument";
import { capitalizeFirstLetter, dictionary, i18n } from "src/utils";
import globalRouter from "src/utils/helpers/globalRouter";
import Private from "./PrivateRoute";
import { Routes } from "./routing";

const RoutesContainer: React.FC = () => {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;
  return (
    <Switch>
      <Route
        path={Routes.LOGIN}
        element={<Login />}
      />
      <Route
        path={Routes.FORGOT_PASSWORD}
        element={<ForgotPassword />}
      />
      <Route
        path={Routes.RESET_PASSWORD}
        element={<ResetPassword />}
      />
      <Route
        path={Routes.AUTO_LOGIN}
        element={<Login />}
      />
      <Route // Antigo modelo de envio dos documentos (não faz parte dos fluxos de admissões)
        path={Routes.SENDING_DOCUMENTS}
        element={<SendingDocuments />}
      />
      <Route // Envio de documentos
        path={Routes.NEW_SENDING_DOCUMENTS}
        element={<NewSendingDocuments />}
      />
      <Route // Contrato de trabalho
        path={Routes.DOWNLOAD_CONTRACT}
        element={<ViewSignDocument id={2} />}
      />
      <Route // Proposta de trabalho
        path={Routes.WORK_PROPOSAL}
        element={<ViewSignDocument id={4} />}
      />
      <Route // Exame admissional
        path={Routes.ADMISSION_EXAM}
        element={<AdmissionExam />}
      />
      <Route // Termo de responsabilidade
        path={Routes.RESPONSIBILITY_TERM}
        element={<ViewSignDocument id={6} />}
      />
      <Route
        path={Routes.HOME}
        element={
          <Private
            title={i18n(dictionary.label.admissional_system)}
            component={Home}
          />
        }
      >
        <Route
          index
          element={
            <Private
              subtitle={i18n(dictionary.label.dashboard)}
              component={Dashboard}
            />
          }
        />
        <Route
          path={Routes.REGISTRATION}
          element={
            <Private
              title={i18n(dictionary.label.registration)}
              component={() => <Outlet />}
            />
          }
        >
          <Route
            path={Routes.EMPLOYER}
            element={
              <Private
                subtitle={capitalizeFirstLetter(i18n(dictionary.entity.employer, { count: 2 }))}
                component={() => <Outlet />}
              />
            }
          >
            <Route
              index
              element={<Private component={Employer} />}
            />
            <Route
              path={Routes.EMPLOYER_CREATE}
              element={<Private component={EmployerForm} />}
            />
            <Route
              path={Routes.EMPLOYER_EDIT}
              element={<Private component={EmployerForm} />}
            />
          </Route>
          <Route
            path={Routes.DOCUMENTATION}
            element={
              <Private
                subtitle={capitalizeFirstLetter(i18n(dictionary.entity.employment_relationships))}
                component={() => <Outlet />}
              />
            }
          >
            <Route
              index
              element={<Private component={Documentation} />}
            />
            <Route
              path={Routes.DOCUMENTATION_CREATE}
              element={<Private component={DocumentationCreate} />}
            />
            <Route
              path={Routes.DOCUMENTATION_EDIT}
              element={<Private component={DocumentationEdit} />}
            />
          </Route>
          <Route
            path={Routes.USER}
            element={
              <Private
                subtitle={capitalizeFirstLetter(i18n(dictionary.entity.user, { count: 2 }))}
                component={() => <Outlet />}
              />
            }
          >
            <Route
              index
              element={<Private component={User} />}
            />
            <Route
              path={Routes.USER_CREATE}
              element={<Private component={UserForm} />}
            />
            <Route
              path={Routes.USER_EDIT}
              element={<Private component={UserForm} />}
            />
          </Route>
          <Route
            path={Routes.EMPLOYEE}
            element={
              <Private
                subtitle={i18n(dictionary.entity.employee, { count: 2 })}
                component={() => <Outlet />}
              />
            }
          >
            <Route
              index
              element={<Private component={Employee} />}
            />
            {/* <Route
              path={Routes.EMPLOYEE_CREATE}
              element={<Private component={EmployeeCreate} />}
            /> */}
            <Route
              path={Routes.EMPLOYEE_EDIT}
              element={<Private component={EmployeeEdit} />}
            />
          </Route>
          {/* Fluxos de admissão */}
          <Route
            path={Routes.ADMISSION_FLOW}
            element={
              <Private
                subtitle={i18n(dictionary.entity.admission_flow, { count: 2 })}
                component={() => <Outlet />}
              />
            }
          >
            <Route
              index
              element={<Private component={AdmissionFlow} />}
            />
            <Route
              path={Routes.ADMISSION_FLOW_CREATE}
              element={<Private component={AdmissionFlowCreateUpdate} />}
            />
            <Route
              path={Routes.ADMISSION_FLOW_EDIT + "/:id"}
              element={<Private component={AdmissionFlowCreateUpdate} />}
            />
          </Route>
        </Route>
        <Route
          path={Routes.REPORT}
          element={
            <Private
              title={i18n(dictionary.label.report, { count: 2 })}
              component={() => <Outlet />}
            />
          }
        >
          <Route
            path={Routes.REPORT_REGISTRATION_FORM}
            element={
              <Private
                subtitle={i18n(dictionary.label.registrationForm, { count: 2 })}
                component={() => <RegistrationForm />}
              />
            }
          />
        </Route>
        <Route
          path={Routes.CONTRACT}
          element={
            <Private
              subtitle={i18n(dictionary.entity.contract)}
              component={() => <Outlet />}
            />
          }
        >
          <Route
            index
            element={<Private component={Contract} />}
          />
          <Route
            path={Routes.CONTRACT_CREATE}
            element={<Private component={ContractCreate} />}
          />
          <Route
            path={Routes.CONTRACT_EDIT}
            element={<Private component={ContractEdit} />}
          />
        </Route>
        <Route
          path={Routes.SETTINGS}
          element={
            <Private
              title={i18n(dictionary.label.admissional_system)}
              component={Settings}
            />
          }
        />
        <Route
          path={Routes.FINANCE}
          element={
            <Private
              title={i18n(dictionary.label.finance)}
              component={Finance}
            />
          }
        />
        <Route
          path={Routes.NEWS}
          element={
            <Private
              title={i18n(dictionary.label.news)}
              component={News}
            />
          }
        />
        <Route
          path={"/*"}
          element={
            <Private
              title={"Not found"}
              component={NotFound}
            />
          }
        />
      </Route>
    </Switch>
  );
};

export { Routes };
export default RoutesContainer;
