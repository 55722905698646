import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Image, Row, Space, Typography } from "antd";
import { CurrentLogo, SendingDocument } from "src/assets/images";
import { REACT_APP_BASE_PATH } from "src/constants";
import styled from "styled-components";

const { Title } = Typography;

const rowStyle: React.CSSProperties = {
  height: "100vh",
};

const contentTop: React.CSSProperties = {
  height: "50vh",
  padding: "20px 0px",
  justifyContent: "center",
};

const contentBottom: React.CSSProperties = {
  marginLeft: "64px",
  marginTop: "32px",
  marginRight: "64px",
};

const StyledTitle = styled(Title)`
  &.ant-typography {
    color: ${(props) => props.color};
  }
`;

type PublicEmployeeStartPageProps = {
  next: () => void;
  data: {
    primaryColor: string;
    secondaryColor: string;
    logo: string | null;
    pagePhrase: string;
    pageDescription: string;
  };
  disabledStart?: boolean;
};

export default function PublicEmployeeStartPage({ next, data, disabledStart }: PublicEmployeeStartPageProps) {
  const stepsStyle: React.CSSProperties = {
    color: "#fff",
    width: "50%",
    backgroundColor: data.primaryColor,
  };

  return (
    <Row style={rowStyle}>
      <Col
        span={12}
        style={stepsStyle}
      >
        <Row style={contentTop}>
          <Image
            width="auto"
            height="100%"
            src={SendingDocument}
            alt="SendingDocumentStart"
            preview={false}
          />
        </Row>
        <Row style={contentBottom}>
          <Row>
            <StyledTitle color="white">{data.pagePhrase}</StyledTitle>
          </Row>
          <Row>
            <StyledTitle
              color="gray"
              level={2}
            ></StyledTitle>
          </Row>
        </Row>
      </Col>
      <Col
        span={12}
        style={{ background: "#F5F7FA" }}
      >
        <Space
          style={{
            width: "100%",
            justifyContent: "end",
            marginBottom: "20%",
            textAlign: "end",
          }}
        >
          <Image
            style={{ marginTop: "48px", marginRight: "48px", height: "100px", width: "100px" }}
            src={!data.logo ? CurrentLogo : `${REACT_APP_BASE_PATH}/download/${data.logo}`}
            alt="Logo"
            preview={false}
          />
        </Space>
        <Row>
          <Space
            direction="horizontal"
            style={{ width: "100%", justifyContent: "center" }}
          >
            <StyledTitle
              style={{ fontSize: "128px", marginBottom: "8px" }}
              color={data.secondaryColor}
            >
              Oiê!
            </StyledTitle>
          </Space>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Space
            direction="horizontal"
            style={{
              width: "100%",
              justifyContent: "center",
              marginLeft: "16%",
              marginRight: "16%",
              height: "60%",
              marginBottom: "64px",
            }}
          >
            <StyledTitle
              color="gray"
              level={5}
            >
              {data.pageDescription}
            </StyledTitle>
          </Space>
        </Row>
        <Row>
          <Space
            direction="horizontal"
            style={{ width: "100%", justifyContent: "center" }}
          >
            <Button
              icon={<ArrowRightOutlined />}
              type="primary"
              htmlType="submit"
              size="large"
              style={{ background: "#6164C2" }}
              onClick={next}
              disabled={disabledStart}
            >
              Iniciar
            </Button>
          </Space>
        </Row>
      </Col>
    </Row>
  );
}
