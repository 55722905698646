import { Divider } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { If } from "..";
import { Content, Header, HeaderWrapper, Title, Wrapper } from "./styles";

type Props = {
  title?: React.ReactNode;
  children: React.ReactNode;
  icon?: React.ReactNode;
  actions?: React.ReactNode;
};

function Card({ title, children, icon, actions }: Props): JSX.Element {
  function HeaderArea(): JSX.Element {
    return (
      <If condition={title !== undefined || icon !== undefined || actions !== undefined}>
        <HeaderWrapper>
          <Header>
            {icon && icon}
            {title && <Title>{title}</Title>}
          </Header>
          {actions}
        </HeaderWrapper>
        <Divider />
      </If>
    );
  }

  return (
    <Wrapper>
      <HeaderArea />
      <Content>{children}</Content>
    </Wrapper>
  );
}

Card.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.any,
  actions: PropTypes.any,
};

export default Card;
