//valores que serão carregados caso as respectivas variáveis de ambiente não estiverem definidas
export const REACT_APP_BASE_PATH = process.env.REACT_APP_BASE_PATH || "";
export const REACT_APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID || "admissao";
export const REACT_APP_INTERCOM = process.env.REACT_APP_INTERCOM || "xos8ywf1";
export const REACT_APP_VERSION = process.env.REACT_APP_VERSION || "1.0.0";
export const REACT_APP_NAME = process.env.REACT_APP_NAME || "Admissão Digital";
export const REACT_APP_KEY_ENCRYPT_URL = process.env.REACT_APP_KEY_ENCRYPT_URL || "";
export const REACT_APP_QRPOINT_ENDPOINT = process.env.REACT_APP_QRPOINT_ENDPOINT || "localhost:8099";
export const REACT_APP_OCR_API_URL = process.env.REACT_APP_OCR_API_URL || "https://api.ocr.space";
export const REACT_APP_OCR_API_TOKEN = process.env.REACT_APP_OCR_API_TOKEN || "token";
