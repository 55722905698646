import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "../request";

export const fetch = async (): Promise<Employee[]> => {
  try {
    const { data } = await request.get(`/employees`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const fetchById = async (id: number): Promise<Employee> => {
  try {
    const { data } = await request.get(`/employees/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const create = async (create: Employee) => {
  try {
    const { data } = await request.post(`/employees`, create);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const updateById = async (id: number, update: Employee) => {
  try {
    const { data } = await request.put(`/employees/${id}`, update);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const destroy = async (id: number) => {
  try {
    const { data } = await request.delete(`/employees/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const turnOn = async (id: number) => {
  try {
    const { data } = await request.put(`/employees/turn-on/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const createDocument = async (id: number, document: string, step: Step, documentFieldName: string) => {
  try {
    const { data } = await request.post(`/employees/create-document/${id}`, { document, step, documentFieldName });
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const signDocument = async (id: number, type: number, document: string, documentId: string) => {
  try {
    const { data } = await request.put(`/employees/sign-document/${id}`, { type, document, documentId });
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const sendAdmissionExam = async (id: number, buffer: Buffer) => {
  try {
    const { status } = await request.post(`/employees/send-admission-exam/${id}`, { buffer });
    return status === 200;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const getEmployerSignatureLink = async (documentId: string) => {
  try {
    const { data } = await request.get(`/employees/employer-signature-link/${documentId}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const downloadDocument = async (documentId?: string, document?: string) => {
  try {
    const { data } = await request.post(
      `/employees/download-document/${documentId}`,
      { document },
      {
        responseType: "blob",
      },
    );
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};
