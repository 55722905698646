import { DownloadOutlined, LinkOutlined, SignatureOutlined } from "@ant-design/icons";
import { Button, Card, Flex, Modal, Tag, Typography, message } from "antd";
import axios from "axios";
import { useState } from "react";
import { AdmissionFlowApi, ContractApi, EmployeeApi } from "src/api";
import { REACT_APP_BASE_PATH } from "src/constants";
import { dictionary, i18n } from "src/utils";

const { Text } = Typography;

type Document = {
  fileId?: string;
  signed?: boolean;
};

export type AdmissionFlowStepDetailsData = {
  open: boolean;
  index: number;
  currentIndex: number;
  stepId: number;
  employeeId: number;
  employeeEmail: string;
  documentId?: number;
  documentName?: string;
  validationType?: 1 | 2;
  workContract?: Document;
  workProposal?: Document;
  responsibilityTerm?: Document;
  admissionExam?: string;
  isLastStep: boolean;
};

type AdmissionFlowStepDetailsProps = {
  data: AdmissionFlowStepDetailsData;
  onCancel: () => void;
};

export default function AdmissionFlowStepDetails({ data, onCancel }: AdmissionFlowStepDetailsProps) {
  const [messageApi, contextHolder] = message.useMessage();

  const isViewSignStep = [2, 4, 6].includes(data.stepId);

  const [downloadingAdmissionExam, setDownloadingAdmissionExam] = useState(false);
  const [gettingEmployerSignature, setGettingEmployerSignature] = useState(false);
  const [downloadingDocument, setDownloadingDocument] = useState(false);
  const [gettingStepLink, setGettingStepLink] = useState(false);
  const [stepLink, setStepLink] = useState("");

  function Status() {
    let options = {
      color: "",
      text: "",
    };

    if (data.index < data.currentIndex || (data.index === data.currentIndex && data.isLastStep)) {
      options = { color: "success", text: i18n(dictionary.components.admissionFlowStepDetails.status.finished) };
    } else if (data.index > data.currentIndex) {
      options = { color: "warning", text: i18n(dictionary.components.admissionFlowStepDetails.status.pending) };
    } else {
      options = { color: "blue", text: i18n(dictionary.components.admissionFlowStepDetails.status.inProgress) };
    }

    return (
      <Tag
        style={{ margin: 0 }}
        color={options.color}
      >
        {options.text}
      </Tag>
    );
  }

  function SignedByEmployee() {
    let signed = false;

    switch (data.stepId) {
      case 2: {
        signed = !!data.workContract?.signed;
        break;
      }
      case 4: {
        signed = !!data.workProposal?.signed;
        break;
      }
      case 5: {
        signed = !!data.admissionExam;
        break;
      }
      case 6: {
        signed = !!data.responsibilityTerm?.signed;
        break;
      }
    }

    return (
      <Tag
        style={{ margin: 0 }}
        color={signed ? "success" : "warning"}
      >
        {i18n(dictionary.label[signed ? "yes" : "no"])}
      </Tag>
    );
  }

  function disableEmployerSignButton() {
    switch (data.stepId) {
      case 2: {
        return !data.workContract?.signed || !data.workContract.fileId;
      }
      case 4: {
        return !data.workProposal?.signed || !data.workProposal.fileId;
      }
      case 6: {
        return !data.responsibilityTerm?.signed || !data.responsibilityTerm.fileId;
      }
    }
  }

  function getDocumentId() {
    switch (data.stepId) {
      case 2:
        return data.workContract?.fileId;
      case 4:
        return data.workProposal?.fileId;
      case 6:
        return data.responsibilityTerm?.fileId;
    }
  }

  async function employerSignature() {
    setGettingEmployerSignature(true);
    const documentId = getDocumentId();
    if (!documentId) return;
    const response = await EmployeeApi.getEmployerSignatureLink(documentId);
    if (response.link) open(response.link, "_blank");
    setGettingEmployerSignature(false);
  }

  function download(fileName: string, blob: Blob) {
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  async function downloadDocument() {
    setDownloadingDocument(true);
    const documentId = getDocumentId();

    let documentContent = "";
    if (!documentId) documentContent = await ContractApi.getFinalDocument(data.documentId || 0, data.employeeId);

    const response = await EmployeeApi.downloadDocument(documentId, documentContent);
    if (response) {
      download(
        i18n(dictionary.components.admissionFlowStepDetails.download.document.fileName[data.stepId as 2 | 4 | 6]),
        response,
      );
    }

    setDownloadingDocument(false);
  }

  async function downloadAdmissionExam() {
    setDownloadingAdmissionExam(true);
    const response = await axios
      .get(REACT_APP_BASE_PATH + "/download/" + data.admissionExam, { responseType: "blob" })
      .then((response) => (response.status === 200 && response.data ? response.data : null))
      .catch((_) => null);
    if (response)
      download(i18n(dictionary.components.admissionFlowStepDetails.download.admissionExam.fileName), response);
    setDownloadingAdmissionExam(false);
  }

  async function getStepLink() {
    setGettingStepLink(true);

    let link = "";

    if (stepLink) {
      link = stepLink;
    } else {
      const response = await AdmissionFlowApi.getStepLink(data.stepId as Step, data.employeeId, data.employeeEmail);
      if (response) link = response;
    }

    await navigator.clipboard.writeText(link);

    setStepLink(link);

    messageApi.destroy();
    messageApi.info(i18n(dictionary.components.admissionFlowStepDetails.stepLink.message));

    setGettingStepLink(false);
  }

  return (
    <>
      {contextHolder}
      <Modal
        title={`${data.index + 1}. ${i18n(
          dictionary.pages.admissionFlowCreate.tabs.t1.cards.others.titles[data.stepId as Step],
        )}`}
        open={data.open}
        footer={false}
        onCancel={() => {
          setStepLink("");
          onCancel();
        }}
      >
        <Card
          style={{ marginTop: 15 }}
          styles={{ body: { padding: "15px 20px" } }}
        >
          <Flex
            vertical
            gap={10}
          >
            {/* Status */}
            <Flex gap={5}>
              <Text>{i18n(dictionary.components.admissionFlowStepDetails.status.label)}:</Text>
              <Status />
            </Flex>
            {/* Documento (caso tenha) */}
            {isViewSignStep && data.documentName && (
              <Flex gap={5}>
                <Text>
                  {i18n(
                    dictionary.pages.admissionFlowCreate.tabs.t1.step.fields.document.select[data.stepId as 2 | 4 | 6],
                  )}
                  :
                </Text>
                <Text style={{ fontWeight: 600 }}>{data.documentName}</Text>
              </Flex>
            )}
            {/* Forma de validação (caso tenha) */}
            {isViewSignStep && data.validationType && (
              <Flex gap={5}>
                <Text>{i18n(dictionary.pages.admissionFlowCreate.tabs.t1.step.fields.validationType.label)}:</Text>
                <Text style={{ fontWeight: 600 }}>
                  {i18n(dictionary.pages.admissionFlowCreate.tabs.t1.step.fields.validationType[data.validationType])}
                </Text>
              </Flex>
            )}
            {/* Colaborador assinou o documento? */}
            {isViewSignStep && data.validationType && (
              <Flex gap={5}>
                <Text>
                  {i18n(dictionary.components.admissionFlowStepDetails.employeeSignature[data.validationType])}:
                </Text>
                <SignedByEmployee />
              </Flex>
            )}
            {/* Assinatura do empregador */}
            {isViewSignStep && data.validationType === 2 && (
              <Flex gap={5}>
                <Text>{i18n(dictionary.components.admissionFlowStepDetails.employerSignature.label)}:</Text>
                <Button
                  ghost
                  type="primary"
                  shape="circle"
                  size="small"
                  icon={<SignatureOutlined />}
                  disabled={disableEmployerSignButton()}
                  onClick={employerSignature}
                  loading={gettingEmployerSignature}
                />
              </Flex>
            )}
            {/* Baixar documento */}
            {isViewSignStep && (
              <Flex gap={5}>
                <Text>{i18n(dictionary.components.admissionFlowStepDetails.download.document.label)}:</Text>
                <Button
                  ghost
                  type="primary"
                  shape="circle"
                  size="small"
                  icon={<DownloadOutlined />}
                  onClick={downloadDocument}
                  loading={downloadingDocument}
                />
              </Flex>
            )}
            {data.stepId === 5 && (
              <>
                {/* Laudo anexado pelo colaborador */}
                <Flex gap={5}>
                  <Text>{i18n(dictionary.components.admissionFlowStepDetails.admissionExam.label)}:</Text>
                  <SignedByEmployee />
                </Flex>
                {/* Baixar laudo */}
                <Flex gap={5}>
                  <Text>{i18n(dictionary.components.admissionFlowStepDetails.admissionExam.download)}:</Text>
                  <Button
                    ghost
                    type="primary"
                    shape="circle"
                    size="small"
                    icon={<DownloadOutlined />}
                    disabled={!data.admissionExam}
                    onClick={downloadAdmissionExam}
                    loading={downloadingAdmissionExam}
                  />
                </Flex>
              </>
            )}
            {data.stepId !== 3 && (
              <Flex gap={5}>
                <Text>{i18n(dictionary.components.admissionFlowStepDetails.stepLink.label)}:</Text>
                <Button
                  ghost
                  type="primary"
                  shape="circle"
                  size="small"
                  icon={<LinkOutlined />}
                  onClick={getStepLink}
                  loading={gettingStepLink}
                  disabled={data.index > data.currentIndex}
                />
              </Flex>
            )}
          </Flex>
        </Card>
      </Modal>
    </>
  );
}
