// RG
const rgPatterns = [
  /^\d{2}\.\d{3}\.\d{3}-\d{2}$/, //         Formato: 00.000.000-00
  /^\d{9}$/, //                             Formato: 000000000
  /^[A-Z]{2}-\d{2}\.\d{3}\.\d{3}$/, //      Formato: AA-00.000.000
  /^[A-Z]{1}\d{2}\.\d{3}\.\d{3}-\d{1}$/, // Formato: A00.000.000-0
  /^\d{2}-\d{2}-\d{5}-\d{1}$/, //           Formato: 00-00-00000-0
];

export function identifyRG(text: string[]) {
  const value = text.map((line) => line.trim()).find((line) => rgPatterns.some((pattern) => pattern.test(line))) ?? "";
  return value.replace(/[.\-]/g, "");
}

// CPF
const cpfPattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

export function identifyCPF(text: string[]) {
  const value = text.map((line) => line.trim()).find((line) => cpfPattern.test(line)) ?? "";
  return value.replace(/[.\-]/g, "");
}

// CNH
const cnhPattern = /^\d{11}$/;

export function identifyCNH(text: string[]) {
  const value = text.map((line) => line.trim()).find((line) => cnhPattern.test(line)) ?? "";
  return value;
}

// TE
const tePattern = /^\d{4} \d{4} \d{4}$/;

export function identifyTE(text: string[]) {
  const value = text.map((line) => line.trim()).find((line) => tePattern.test(line)) ?? "";
  return value.replace(/\s+/g, "");
}

const sectionPattern = /^\d{4}$/;

export function identifySection(text: string[]) {
  const value = text.map((line) => line.trim()).find((line) => sectionPattern.test(line)) ?? "";
  return value;
}

const zonePattern = /^\d{3}$/;

export function identifyZone(text: string[]) {
  const value = text.map((line) => line.trim()).find((line) => zonePattern.test(line)) ?? "";
  return value;
}

// CTPS
const ctpsPattern = /^\d{7}$/;

export function identifyCTPS(text: string[]) {
  const value = text.map((line) => line.trim()).find((line) => ctpsPattern.test(line)) ?? "";
  return value;
}
