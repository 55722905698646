import { css, styled } from "src/modules";

type ActiveProps = {
  active?: boolean;
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;

  svg {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.brand.primary.main};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 38px;
  border-radius: 8px;
  margin-left: 16px;

  @media (max-width: 600px) {
    padding-bottom: 16px;
  }
`;

export const Item = styled.div<ActiveProps>`
  ${({ theme, active }) => css`
    margin-right: 32px;
    border-bottom: 2px solid ${active ? theme.colors.brand.secondary.pink : theme.colors.white};

    p {
      color: ${active ? theme.colors.brand.secondary.pink : theme.colors.text};
      font-weight: 600;
      font-size: 16px;
    }

    &:hover {
      cursor: pointer;
    }
  `}
`;
