import React, { useContext } from "react";

import { RoutingContext } from "src/contexts";
import AdmissionExam from "./AdmissionExam";

const AdmissionExamContainer: React.FC = () => {
  const { setTitle, setSubtitle } = useContext(RoutingContext);
  setTitle("Sistema de admissões");
  setSubtitle("Dashboard");

  return <AdmissionExam />;
};

export default AdmissionExamContainer;
