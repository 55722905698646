import { BookOutlined, DollarCircleOutlined, FileOutlined, HomeOutlined } from "@ant-design/icons";
import { Flex, Image, Layout, List, Menu, Popover, Typography } from "antd";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "src/routes";
import { Keys } from "src/routes/routing";
import COLOR from "src/styles/colors";
import { dictionary, i18n } from "src/utils";
import logo from "../../../assets/images/logo-atual-colorida-simples.png";
import "./DesktopMenu.css";

const { Sider } = Layout;
const { Text, Title } = Typography;

type Item = {
  key: number;
  label: string;
  route: string;
};

export default function DesktopMenu() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Sider
      className="desktop-menu"
      theme="light"
      width={140}
    >
      <Flex className="desktop-menu-header">
        <Image
          src={logo}
          preview={false}
          width={70}
        />
        <Text style={{ fontWeight: 600, color: COLOR.PURPLE }}>QRAdmissão</Text>
      </Flex>
      <Menu
        mode="inline"
        className="desktop-menu-content"
        selectedKeys={[location.pathname.split("/")[1]]}
        items={[
          {
            className: "desktop-menu-content-item",
            key: Keys.HOME,
            label: (
              <Flex
                vertical
                justify="center"
                align="center"
              >
                <HomeOutlined />
                {i18n(dictionary.menu.home)}
              </Flex>
            ),
            onClick: () => navigate(Routes.HOME),
          },
          {
            className: "desktop-menu-content-item",
            key: Keys.REGISTRATION,
            label: (
              <MenuPopover
                icon={BookOutlined}
                title={i18n(dictionary.menu.registration)}
                items={[
                  { key: 1, label: i18n(dictionary.menu.employer), route: Routes.EMPLOYER },
                  { key: 2, label: i18n(dictionary.menu.employee), route: Routes.EMPLOYEE },
                  { key: 3, label: i18n(dictionary.menu.contract), route: Routes.CONTRACT },
                  { key: 4, label: i18n(dictionary.menu.admissionFlow), route: Routes.ADMISSION_FLOW },
                  { key: 5, label: i18n(dictionary.menu.employmentRelationship), route: Routes.DOCUMENTATION },
                ]}
              >
                <Flex
                  vertical
                  justify="center"
                  align="center"
                >
                  <BookOutlined />
                  {i18n(dictionary.menu.registration)}
                </Flex>
              </MenuPopover>
            ),
          },
          {
            className: "desktop-menu-content-item",
            key: Keys.REPORT,
            label: (
              <MenuPopover
                icon={FileOutlined}
                title={i18n(dictionary.menu.report)}
                items={[
                  { key: 1, label: i18n(dictionary.menu.registrationForm), route: Routes.REPORT_REGISTRATION_FORM },
                ]}
              >
                <Flex
                  vertical
                  justify="center"
                  align="center"
                >
                  <FileOutlined />
                  {i18n(dictionary.menu.report)}
                </Flex>
              </MenuPopover>
            ),
          },
          {
            className: "desktop-menu-content-item",
            key: Keys.FINANCE,
            label: (
              <Flex
                vertical
                justify="center"
                align="center"
              >
                <DollarCircleOutlined />
                {i18n(dictionary.menu.finance)}
              </Flex>
            ),
            onClick: () => navigate(Routes.FINANCE),
          },
        ]}
      ></Menu>
    </Sider>
  );
}

type CustomPopoverProps = {
  children: React.ReactNode;
  icon: React.ElementType;
  title: string;
  items: Item[];
};

function MenuPopover({ children, icon: Icon, title, items }: CustomPopoverProps) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      arrow={false}
      placement="right"
      overlayInnerStyle={{ marginLeft: 25, borderRadius: 16 }}
      title={
        <Title
          level={5}
          style={{ color: COLOR.PURPLE, margin: 0 }}
        >
          <Icon />
          &nbsp;{title}
        </Title>
      }
      content={
        <List
          style={{ padding: "0 10px" }}
          dataSource={items}
          renderItem={(item) => (
            <List.Item key={item.key}>
              <Text
                className="desktop-menu-content-subitem"
                onClick={() => {
                  navigate(item.route);
                  setOpen(false);
                }}
                style={{
                  cursor: "pointer",
                  color: COLOR.PURPLE,
                }}
              >
                {item.label}
              </Text>
            </List.Item>
          )}
        />
      }
    >
      {children}
    </Popover>
  );
}
