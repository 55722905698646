import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled.h5`
  color: ${({ theme }) => theme.colors.brand.secondary.purple};
  font-weight: 700;
  font-size: 16px;
  margin: 0;
  margin-left: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-flow: row wrap;
`;
