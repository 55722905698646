import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { NewApi } from "src/api";
import { MenuItem } from "src/pages/_layouts/default";
import { StyledHeader } from "src/pages/_layouts/default/styles";
import StorageService from "src/services/storage/storage.service";
import MobileMenu from "../Menu/MobileMenu/MobileMenu";
import { AccountDropdown, NavbarSearch } from "../navbar";
import SystemSelector from "../navbar/SystemSelector";

type Props = {
  hasGradient?: boolean;
  hasLogo?: boolean;
  title?: string;
  subtitle?: string;
  tourRef?: any;
  menuItems?: MenuItem[];
  onClickLogo?: () => void;
};

const notifications: any[] = [];

const Header: React.FC<Props> = ({
  hasGradient = false,
  hasLogo = false,
  title = "",
  subtitle = "",
  tourRef,
  menuItems,
  onClickLogo,
}) => {
  const [isWideScreen, setIsWideScreen] = useState(window.matchMedia("(min-width: 600px)").matches);

  const [openPopover, setOpenPopover] = useState(true);
  const [news, setNews] = useState<any[]>([]);
  const [showNewsDot, setShowNewsDot] = useState(false);

  const navigate = useNavigate();

  const onBack = () => navigate(-1);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setIsWideScreen(windowWidth >= 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    NewApi.fetch(1, 4).then((data) => setNews(data));
  }, []);

  useEffect(() => {
    if (news.length) {
      const latestNew = StorageService.getLatestNew();
      if (!latestNew) setShowNewsDot(true);
      const userId = StorageService.getUserId();
      setShowNewsDot(`${userId}_${news[0].id}` !== latestNew);
    }
  }, [news]);

  return (
    <StyledHeader className="custom-header">
      <SystemSelector />
      <NavbarSearch menuItems={menuItems} />
      <AccountDropdown />
      <MobileMenu />
    </StyledHeader>
  );
};

export default memo(Header);
