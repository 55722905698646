import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
  CodeOutlined,
  FontSizeOutlined,
  ItalicOutlined,
  LineHeightOutlined,
  MenuOutlined,
  UnderlineOutlined,
} from "@ant-design/icons";
import { Select, Space } from "antd";
import isHotkey from "is-hotkey";
import { useCallback, useMemo } from "react";
import { BaseEditor, Descendant, Editor, Node, Element as SlateElement, Transforms, createEditor } from "slate";
import { HistoryEditor, withHistory } from "slate-history";
import { Editable, ReactEditor, Slate, useSlate, withReact } from "slate-react";
import { Button, Toolbar } from "src/components/RichTextEditor/Components";
import { Storage } from "src/services";
import { CONTRACT_TEXT_FIELD, dictionary, i18n } from "src/utils";
import { formatField } from "src/utils/functions/contract";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

type CustomElement = {
  type: "paragraph" | "block-quote";
  children: CustomText[];
  align?: "center" | "left" | "right" | "justify";
};
type CustomText = { text: string; bold?: boolean; italic?: boolean; code?: boolean; field?: boolean };

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

interface RichTextEditorProps {
  content: Descendant[];
  onChange?: React.Dispatch<React.SetStateAction<Descendant[]>>;
  fieldList?: CONTRACT_TEXT_FIELD[];
  style?: any;
  readOnly: boolean;
}

const RichTextEditor = (props: RichTextEditorProps) => {
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  // Update the initial content to be pulled from Local Storage if it exists.
  const initialValue: Descendant[] = useMemo(
    () =>
      JSON.parse(Storage.getContractText()) || [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ],
    [],
  );

  return (
    <Slate
      editor={editor}
      initialValue={props.content ?? initialValue}
      onChange={(value) => {
        const isAstChange = editor.operations.some((op) => "set_selection" !== op.type);
        if (isAstChange) {
          if (!!props.onChange) props.onChange(value);
          // Save the value to Local Storage.
          // const content = JSON.stringify(value);
          Storage.setContractText(value);
        }
      }}
    >
      {!props.readOnly && (
        <Toolbar>
          <MarkButton
            format="bold"
            icon={<BoldOutlined />}
          />
          <MarkButton
            format="italic"
            icon={<ItalicOutlined />}
          />
          <MarkButton
            format="underline"
            icon={<UnderlineOutlined />}
          />
          <MarkButton
            format="code"
            icon={<CodeOutlined />}
          />
          <BlockButton
            format="heading-one"
            icon={<LineHeightOutlined />}
          />
          <BlockButton
            format="heading-two"
            icon={<FontSizeOutlined />}
          />
          {/* <BlockButton
          format="numbered-list"
          icon={<OrderedListOutlined />}
        /> */}
          {/* <BlockButton
          format="bulleted-list"
          icon={<UnorderedListOutlined />}
        /> */}
          <BlockButton
            format="left"
            icon={<AlignLeftOutlined />}
          />
          <BlockButton
            format="center"
            icon={<AlignCenterOutlined />}
          />
          <BlockButton
            format="right"
            icon={<AlignRightOutlined />}
          />
          <BlockButton
            format="justify"
            icon={<MenuOutlined />}
          />

          {props.fieldList && (
            <Space wrap>
              <FieldSelect
                {...props}
                format="field"
              />
            </Space>
          )}
        </Toolbar>
      )}
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder={i18n(dictionary.enterContractText)}
        spellCheck
        autoFocus
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event as any)) {
              event.preventDefault();
              const mark = (HOTKEYS as any)[hotkey];
              toggleMark(editor, mark);
            }
          }
        }}
        style={{
          ...props.style,
          overflow: "auto",
          padding: "8px 8px",
          minHeight: "200px",
          outline: "solid 2px",
        }}
        readOnly={props.readOnly}
      />
    </Slate>
  );
};

const toggleBlock = (editor: any, format: any) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type");
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (matchParam: Node) =>
      !Editor.isEditor(matchParam) &&
      SlateElement.isElement(matchParam) &&
      LIST_TYPES.includes(matchParam.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: Partial<SlateElement>;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor: any, format: any) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor: any, format: any, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && (n as any)[blockType] === format,
    }),
  );

  return !!match;
};

const isMarkActive = (editor: any, format: string) => {
  const marks = Editor.marks(editor);
  return marks ? (marks as any)[format] === true : false;
};

const Element = ({ attributes, children, element }: { attributes: any; children: any; element: any }) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case "block-quote":
      return (
        <blockquote
          style={style}
          {...attributes}
        >
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul
          style={style}
          {...attributes}
        >
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1
          style={style}
          {...attributes}
        >
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2
          style={style}
          {...attributes}
        >
          {children}
        </h2>
      );
    case "list-item":
      return (
        <li
          style={style}
          {...attributes}
        >
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol
          style={style}
          {...attributes}
        >
          {children}
        </ol>
      );
    default:
      return (
        <p
          style={style}
          {...attributes}
        >
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }: { attributes: any; children: any; leaf: any }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.field) {
    children = <strong>{children}</strong>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }: { format: any; icon: any }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type")}
      onMouseDown={(event: any) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon}
    </Button>
  );
};

const MarkButton = ({ format, icon }: { format: any; icon: any }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event: any) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </Button>
  );
};

const FieldSelect = (props: any) => {
  const editor = useSlate();

  const handleChange = (value: string) => {
    const field = formatField(value);
    editor.insertText(field);

    if (editor.selection) {
      editor.setSelection({
        anchor: editor.selection.anchor,
        focus: { path: editor.selection.anchor.path, offset: -field.length },
      });
      editor.addMark(props.format, true);

      editor.setSelection({
        anchor: editor.selection.anchor,
        focus: { path: editor.selection.anchor.path, offset: -1 },
      });
      editor.addMark(props.format, false);
    }
  };

  return (
    <Select
      onChange={handleChange}
      options={props.fieldList.map((field: any) => {
        return {
          label: i18n(dictionary.contractTextField.label.concat(field)),
          value: i18n(dictionary.contractTextField.value.concat(field)),
        };
      })}
      value={i18n(dictionary.insert, { item: i18n(dictionary.field) })}
      onMouseDown={(event: any) => {
        event.preventDefault();
      }}
      disabled={isMarkActive(editor, props.format)}
      showSearch
    />
  );
};

export default RichTextEditor;
