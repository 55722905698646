import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  EditOutlined,
  EyeOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Steps,
  Switch,
  Tag,
  Typography,
  Upload,
  message,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { AdmissionApi, AdmissionFlowApi, DocumentApi, OcrApi } from "src/api";
import { RequireDocuments } from "src/api/auth/admission.api";
import { CurrentLogo, SendingDocument } from "src/assets/images";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import PublicEmployeeStartPage from "src/components/PublicEmployeeStartPage";
import { REACT_APP_BASE_PATH } from "src/constants";
import { countryStates } from "src/data";
import {
  ExpiredSessionError,
  dictionary,
  getDescryptedParamsData,
  i18n,
  identifyCNH,
  identifyCPF,
  identifyCTPS,
  identifyRG,
  identifySection,
  identifyTE,
  identifyZone,
} from "src/utils";
import styled from "styled-components";

const { Text, Title, Link } = Typography;

const STEP_ID = 1;

const rowStyle: React.CSSProperties = {
  height: "100vh",
};

const contentBottom: React.CSSProperties = {
  marginLeft: "64px",
  marginTop: "32px",
  marginRight: "64px",
};

const StyledTitle = styled(Title)`
  &.ant-typography {
    color: ${(props) => props.color};
  }
`;

const StyledText = styled(Text)`
  &.ant-typography {
    color: ${(props) => props.color};
  }
`;

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

type DocumentWithOCR = "RG" | "CPF" | "CNH" | "CT" | "TE";

export default function NewSendingDocuments() {
  const params = useParams();

  const { show } = useIntercom();

  const [form] = useForm();

  const [loadingData, setLoadingData] = useState(true);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [current, setCurrent] = useState(-1);
  const [_, setFile] = useState("");
  const [urlUploadedFile, setUrlUploadedFile] = useState("");
  const [enableSend, setEnableSend] = useState(false);
  const [enableNext, setEnableNext] = useState(false);
  const [documentsSended, setDocumentsSended] = useState(false);
  const [withoutRequireDocuments, setWithoutRequireDocuments] = useState(true);
  const [employeeData, setEmployeeData] = useState({
    id: "",
    email: "",
  });
  const [data, setData] = useState({
    primaryColor: "#884ea6",
    secondaryColor: "#66C790",
    logo: "",
    pagePhrase: "",
    pageDescription: "",
  });
  const [requireDocuments, setRequireDocuments] = useState<RequireDocuments>({
    deadline: 30,
    employerName: "",
    documentCodes: ["RG"],
    contractText: "",
    employeeId: 0,
    admissionStatus: 1,
    attachmentCodes: ["RG"],
  });
  const [isDigitalCtps, setIsDigitalCtps] = useState(false);

  const [fields, setFields] = useState<Record<DocumentWithOCR, { enabled: boolean; editable: boolean }>>({
    RG: { enabled: false, editable: false },
    CPF: { enabled: false, editable: false },
    CNH: { enabled: false, editable: false },
    CT: { enabled: false, editable: false },
    TE: { enabled: false, editable: false },
  });

  const stepsStyle: React.CSSProperties = {
    color: "#fff",
    width: "50%",
    backgroundColor: data.primaryColor ?? "#884EA6",
  };

  async function onFinish(values: any) {
    if (values.documentCode === "CT" && isDigitalCtps) {
      next();
      return;
    }

    values.employeeId = requireDocuments.employeeId;

    if (values.documentCode === "CO") values.contract = requireDocuments.contractText;

    AdmissionApi.sendDocument(values)
      .then((_) => {
        next();
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.SEND_DOCUMENT_ERROR.title),
            message: i18n(dictionary.error.SEND_DOCUMENT_ERROR.message),
            onOk: () => onFinish(values),
            onClickLink: () => show(),
          });
        }
      });
  }

  async function onFinishSendDocument() {
    AdmissionApi.finishSendDocument(requireDocuments.employeeId).catch((error) => {
      if (!(error instanceof ExpiredSessionError)) {
        GoodErrorMessage({
          title: i18n(dictionary.error.SEND_DOCUMENT_ERROR.title),
          message: i18n(dictionary.error.SEND_DOCUMENT_ERROR.message),
          onOk: () => onFinishSendDocument(),
          onClickLink: () => show(),
        });
      }
    });
  }

  async function getRequireDocuments() {
    AdmissionApi.getSendingDocuments(employeeData.email ?? "")
      .then((res: RequireDocuments) => {
        if (res.deadline <= 0) {
          Modal.error({
            title: "Prazo expirado!",
            content: "O prazo para envio dos documentos expirou.",
            closable: false,
            footer: false,
          });
        } else if (res.admissionStatus >= 2) {
          Modal.error({
            title: "Documentos já foram enviados!",
            content: "Os documentos já foram enviados, por favor aguarde a finalização do processo de admissão.",
            closable: false,
            footer: false,
          });
        }
        setRequireDocuments(res);
        setWithoutRequireDocuments(false);
        setLoadingData(false);
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_REQUIRE_DOCUMENT.title),
            message: i18n(dictionary.error.GET_REQUIRE_DOCUMENT.message),
            onClickLink: () => show(),
            onOk: async () => {
              getData();
            },
          });
        }
        console.error(error);
      });
  }

  async function getData() {
    if (employeeData.id && employeeData.email) {
      const responseData = await AdmissionFlowApi.findByEmployeeId(employeeData.id);
      if (responseData) {
        const stepData = responseData.admissionFlow.flowSteps.find(
          ({ stepId }: { stepId: number }) => stepId === STEP_ID,
        );
        setData({
          primaryColor: responseData.admissionFlow.primaryColor,
          secondaryColor: responseData.admissionFlow.secondaryColor,
          logo: responseData.admissionFlow.logo,
          pagePhrase: stepData.pagePhrase,
          pageDescription: stepData.pageDescription,
        });
        await getRequireDocuments(); // TODO: Mudar para "employeeData.email";
      }
    }
  }

  useEffect(() => {
    getData();
  }, [employeeData]);

  useEffect(() => {
    const paramsData = getDescryptedParamsData(params.data || "");
    if (paramsData && paramsData.employeeId && paramsData.email) {
      setEmployeeData({
        id: paramsData.employeeId,
        email: paramsData.email,
      });
    }
  }, []);

  function getBase64(img: any, callback: (result: any) => void) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const bytesToMb = (bytes: number) => bytes / 1000000;

  const fileIsTooLarge = (file: any) => bytesToMb(file.size) > 1;

  const fileIsImage = (file: any) => file.type.startsWith("image/");

  // TODO: Loading enquanto OCR faz leitura do arquivo;

  function handleUpload(info: any, documentCode: string) {
    if (info.event) {
      if (!fileIsImage(info.file)) {
        Modal.warning({
          title: i18n(dictionary.error.ATTACH_DOCUMENT_ERROR_IMAGE.title),
          content: i18n(dictionary.error.ATTACH_DOCUMENT_ERROR_IMAGE.message),
        });
        return;
      }

      if (fileIsTooLarge(info.file)) {
        Modal.warning({
          title: i18n(dictionary.error.ATTACH_DOCUMENT_ERROR_SIZE.title),
          content: i18n(dictionary.error.ATTACH_DOCUMENT_ERROR_SIZE.message),
        });
        return;
      }

      getBase64(info.file.originFileObj, async (fileURL) => {
        setUploadingFile(true);
        if (["RG", "CPF", "CNH", "CT", "TE"].includes(documentCode)) {
          setFields((prevData) => ({
            ...prevData,
            [documentCode]: { ...prevData[documentCode as DocumentWithOCR], editable: true },
          }));
          const result = await OcrApi.recognize(info.file.originFileObj);
          if (result) {
            const splittedResult = result.split("\n");
            switch (documentCode) {
              case "RG": {
                const rg = identifyRG(splittedResult);
                form.setFieldValue("identity", rg);
                break;
              }
              case "CPF": {
                const cpf = identifyCPF(splittedResult);
                form.setFieldValue("cpf", cpf);
                break;
              }
              case "CNH": {
                const cnh = identifyCNH(splittedResult);
                form.setFieldValue("driverLicense", cnh);
                break;
              }
              case "CT": {
                const ctps = identifyCTPS(splittedResult);
                form.setFieldValue("workCardNumber", ctps);
                const serie = identifySection(splittedResult);
                form.setFieldValue("workCardSerie", serie);
                break;
              }
              case "TE": {
                const te = identifyTE(splittedResult);
                form.setFieldValue("voterRegistrationNumber", te);
                const section = identifySection(splittedResult);
                form.setFieldValue("voterRegistrationSection", section);
                const zone = identifyZone(splittedResult);
                form.setFieldValue("voterRegistrationZone", zone);
                break;
              }
            }
          }
        }

        const fileBuffer = fileURL.replace(/(data:image\/(jpeg)*(gif)*(png)*(gif)*\;(base64),)/g, "");
        setFile(fileBuffer);
        DocumentApi.uploadFile(fileBuffer, documentCode, requireDocuments.employeeId)
          .then((res: any) => {
            setUrlUploadedFile(REACT_APP_BASE_PATH + "/download/" + res.url);
          })
          .catch((error) => {
            if (!(error instanceof ExpiredSessionError)) {
              GoodErrorMessage({
                title: i18n(dictionary.error.ATTACH_DOCUMENT_ERROR.title),
                message: i18n(dictionary.error.ATTACH_DOCUMENT_ERROR.message),
                onClickLink: () => show(),
                onOk: () => handleUpload(info, documentCode),
              });
            }
            console.error(error);
          });

        setUploadingFile(false);
      });
    }
  }

  function cleanImage() {
    setFile("");
    setUrlUploadedFile("");
  }

  const next = async () => {
    const next = current + 1;
    if (steps[next].key === "CO") {
      await getRequireDocuments();
    }
    setCurrent(next);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  function EditSuffix({ document }: { document: DocumentWithOCR }) {
    return (
      <EditOutlined
        disabled={uploadingFile}
        style={{ color: !uploadingFile ? "#884EA6" : undefined }}
        onClick={() =>
          setFields((prevData) => ({
            ...prevData,
            [document]: { ...prevData[document], enabled: !prevData[document].enabled },
          }))
        }
      />
    );
  }

  const steps = [
    {
      title: <StyledText color="white">Documento de identidade (RG)</StyledText>,
      key: "RG",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "48px", marginRight: "48px", height: "100px", width: "100px" }}
              src={!data.logo ? CurrentLogo : `${REACT_APP_BASE_PATH}/download/${data.logo}`}
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "RG" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      Documento de identidade (RG)
                    </StyledTitle>
                  }
                >
                  <Form.Item noStyle>
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          name="identity"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="00.000.000-00"
                            size="large"
                            disabled={uploadingFile || !fields.RG.enabled}
                            suffix={fields.RG.editable && <EditSuffix document="RG" />}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="identityAttachment"
                            noStyle
                            rules={[
                              {
                                required: requireDocuments.attachmentCodes.includes("RG"),
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "RG")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                                loading={uploadingFile}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                            disabled={uploadingFile}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
                <Tag
                  style={{ marginRight: 0 }}
                  color="gold"
                >
                  Anexe o arquivo para preencher o <span style={{ fontWeight: 600 }}>RG</span> automaticamente!
                </Tag>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">CPF</StyledText>,
      key: "CPF",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "48px", marginRight: "48px", height: "100px", width: "100px" }}
              src={!data.logo ? CurrentLogo : `${REACT_APP_BASE_PATH}/download/${data.logo}`}
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "CPF" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      CPF
                    </StyledTitle>
                  }
                >
                  <Form.Item noStyle>
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          name="cpf"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="000.000.000-00"
                            size="large"
                            disabled={uploadingFile || !fields.CPF.enabled}
                            suffix={fields.CPF.editable && <EditSuffix document="CPF" />}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="cpfAttachment"
                            noStyle
                            rules={[
                              {
                                required: requireDocuments.attachmentCodes.includes("CPF"),
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "CPF")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                                loading={uploadingFile}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                            disabled={uploadingFile}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
                <Tag
                  style={{ marginRight: 0 }}
                  color="gold"
                >
                  Anexe o arquivo para preencher o <span style={{ fontWeight: 600 }}>CPF</span> automaticamente!
                </Tag>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">PIS/PASEB</StyledText>,
      key: "PIS",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "48px", marginRight: "48px", height: "100px", width: "100px" }}
              src={!data.logo ? CurrentLogo : `${REACT_APP_BASE_PATH}/download/${data.logo}`}
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "PIS" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      PIS/PASEB
                    </StyledTitle>
                  }
                >
                  <Form.Item noStyle>
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          name="pis"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="000.00000.00-0"
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="pisAttachment"
                            noStyle
                            rules={[
                              {
                                required: requireDocuments.attachmentCodes.includes("PIS"),
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "PIS")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">Carteira de trabalho</StyledText>,
      key: "CT",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "48px", marginRight: "48px", height: "100px", width: "100px" }}
              src={!data.logo ? CurrentLogo : `${REACT_APP_BASE_PATH}/download/${data.logo}`}
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "CT" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                {!isDigitalCtps && (
                  <>
                    <Form.Item
                      label={
                        <StyledTitle
                          color="gray"
                          level={2}
                        >
                          Carteira de trabalho
                        </StyledTitle>
                      }
                    >
                      <Form.Item noStyle>
                        <Row gutter={8}>
                          <Col span={19}>
                            <Form.Item
                              name="workCardNumber"
                              noStyle
                              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                            >
                              <Input
                                placeholder="0000000"
                                size="large"
                                disabled={uploadingFile || !fields.CT.enabled}
                                suffix={fields.CT.editable && <EditSuffix document="CT" />}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Space>
                              <Form.Item
                                name="workCardAttachment"
                                noStyle
                                rules={[
                                  {
                                    required: requireDocuments.attachmentCodes.includes("CT"),
                                    message: i18n(dictionary.message.requiredAttachment),
                                  },
                                ]}
                              >
                                <Upload
                                  accept={"image/*"}
                                  showUploadList={false}
                                  multiple={false}
                                  onChange={(info) => handleUpload(info, "CT")}
                                >
                                  <Button
                                    type="primary"
                                    size="large"
                                    icon={<UploadOutlined />}
                                    loading={uploadingFile}
                                  />
                                </Upload>
                              </Form.Item>
                              <Button
                                type="primary"
                                size="large"
                                icon={<EyeOutlined />}
                                onClick={() => {
                                  Modal.info({
                                    title: "Visualização",
                                    content: <Image src={urlUploadedFile} />,
                                  });
                                }}
                                disabled={uploadingFile}
                              />
                            </Space>
                          </Col>
                        </Row>
                      </Form.Item>
                    </Form.Item>
                    <Space>
                      <Form.Item
                        label={
                          <StyledTitle
                            color="gray"
                            level={4}
                          >
                            Série
                          </StyledTitle>
                        }
                      >
                        <Form.Item
                          name="workCardSerie"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="0000"
                            size="large"
                            disabled={uploadingFile}
                          />
                        </Form.Item>
                      </Form.Item>
                      <Form.Item
                        label={
                          <StyledTitle
                            color="gray"
                            level={4}
                          >
                            UF
                          </StyledTitle>
                        }
                      >
                        <Form.Item
                          name="workCardState"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Select
                            allowClear
                            size="large"
                            style={{ width: "65px" }}
                          >
                            {countryStates.map((countryState) => (
                              <Select.Option
                                key={countryState.abbreviation}
                                title={countryState.abbreviation}
                                value={countryState.abbreviation}
                              >
                                {countryState.abbreviation}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Form.Item>
                    </Space>
                  </>
                )}
                {!isDigitalCtps && (
                  <Form.Item>
                    <Tag
                      style={{ marginRight: 0 }}
                      color="gold"
                    >
                      Anexe o arquivo para preencher os campos automaticamente!
                    </Tag>
                  </Form.Item>
                )}
                <Row>
                  <Form.Item
                    label={
                      <StyledTitle
                        color="gray"
                        level={5}
                      >
                        Carteira digital?
                      </StyledTitle>
                    }
                  >
                    <Form.Item noStyle>
                      <Switch
                        value={isDigitalCtps}
                        onChange={(value) => setIsDigitalCtps(value)}
                        disabled={uploadingFile}
                      />
                    </Form.Item>
                  </Form.Item>
                </Row>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">Título eleitoral</StyledText>,
      key: "TE",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "48px", marginRight: "48px", height: "100px", width: "100px" }}
              src={!data.logo ? CurrentLogo : `${REACT_APP_BASE_PATH}/download/${data.logo}`}
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "TE" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      Título eleitoral
                    </StyledTitle>
                  }
                >
                  <Form.Item noStyle>
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          name="voterRegistrationNumber"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="0000 0000 0000"
                            size="large"
                            disabled={uploadingFile || !fields.TE.enabled}
                            suffix={fields.TE.editable && <EditSuffix document="TE" />}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="voterRegistrationAttachment"
                            noStyle
                            rules={[
                              {
                                required: requireDocuments.attachmentCodes.includes("TE"),
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "TE")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                                loading={uploadingFile}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                            disabled={uploadingFile}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
                <Space>
                  <Form.Item
                    label={
                      <StyledTitle
                        color="gray"
                        level={4}
                      >
                        Seção
                      </StyledTitle>
                    }
                  >
                    <Form.Item
                      name="voterRegistrationSection"
                      noStyle
                      rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                    >
                      <Input
                        style={{ width: "100px" }}
                        maxLength={4}
                        placeholder="0000"
                        size="large"
                        disabled={uploadingFile}
                      />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    label={
                      <StyledTitle
                        color="gray"
                        level={4}
                      >
                        Zona eleitoral
                      </StyledTitle>
                    }
                    style={{ minWidth: "20px" }}
                  >
                    <Form.Item
                      name="voterRegistrationZone"
                      noStyle
                      rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                    >
                      <Input
                        style={{ width: "60px" }}
                        maxLength={3}
                        placeholder="000"
                        size="large"
                        disabled={uploadingFile}
                      />
                    </Form.Item>
                  </Form.Item>
                </Space>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Tag
                    style={{ marginRight: 0 }}
                    color="gold"
                  >
                    Anexe o arquivo para preencher os campos automaticamente!
                  </Tag>
                </Form.Item>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">CNH</StyledText>,
      key: "CNH",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "48px", marginRight: "48px", height: "100px", width: "100px" }}
              src={!data.logo ? CurrentLogo : `${REACT_APP_BASE_PATH}/download/${data.logo}`}
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "CNH" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      CNH
                    </StyledTitle>
                  }
                >
                  <Form.Item noStyle>
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          name="driverLicense"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="00000000000"
                            size="large"
                            disabled={uploadingFile || !fields.CNH.enabled}
                            suffix={fields.CNH.editable && <EditSuffix document="CNH" />}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="driverLicenseAttachment"
                            noStyle
                            rules={[
                              {
                                required: requireDocuments.attachmentCodes.includes("CNH"),
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "CNH")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                                loading={uploadingFile}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                            disabled={uploadingFile}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Tag
                    style={{ marginRight: 0 }}
                    color="gold"
                  >
                    Anexe o arquivo para preencher o campo automaticamente!
                  </Tag>
                </Form.Item>
                <Row gutter={8}>
                  <Col span={19}>
                    <Form.Item
                      label={
                        <StyledTitle
                          color="gray"
                          level={4}
                        >
                          Validade
                        </StyledTitle>
                      }
                    >
                      <Form.Item
                        name="driverLicenseExpirationDate"
                        noStyle
                        rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                      >
                        <DatePicker
                          size="large"
                          placeholder="Selecione a data"
                          style={{ width: "100%" }}
                          format={"DD/MM/YYYY"}
                        />
                      </Form.Item>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">Número de reservista</StyledText>,
      key: "NR",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "48px", marginRight: "48px", height: "100px", width: "100px" }}
              src={!data.logo ? CurrentLogo : `${REACT_APP_BASE_PATH}/download/${data.logo}`}
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "NR" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      Número de reservista
                    </StyledTitle>
                  }
                >
                  <Form.Item noStyle>
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          name="reservistNumber"
                          noStyle
                          rules={[{ required: false, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="000000000000"
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="reservistAttachment"
                            noStyle
                            rules={[
                              {
                                required: false,
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "NR")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={4}
                    >
                      Série
                    </StyledTitle>
                  }
                >
                  <Form.Item
                    name="reservistSerie"
                    noStyle
                    rules={[{ required: false, message: i18n(dictionary.message.requiredField) }]}
                  >
                    <Row gutter={8}>
                      <Col span={19}>
                        <Input
                          size="large"
                          placeholder="S"
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">Comprovante de endereço</StyledText>,
      key: "CE",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "10%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "48px", marginRight: "48px", height: "100px", width: "100px" }}
              src={!data.logo ? CurrentLogo : `${REACT_APP_BASE_PATH}/download/${data.logo}`}
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "CE" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      Comprovante de endereço
                    </StyledTitle>
                  }
                >
                  <Form.Item
                    label={
                      <StyledTitle
                        color="gray"
                        level={4}
                      >
                        Logradouro
                      </StyledTitle>
                    }
                  >
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          name="publicPlace"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="Logradouro"
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="addressAttachment"
                            noStyle
                            rules={[
                              {
                                required: requireDocuments.attachmentCodes.includes("CE"),
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "CE")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Space>
                    <Form.Item
                      label={
                        <StyledTitle
                          color="gray"
                          level={4}
                        >
                          Número
                        </StyledTitle>
                      }
                    >
                      <Form.Item
                        name="addressNumber"
                        noStyle
                      >
                        <Input
                          placeholder="Número"
                          size="large"
                        />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item
                      label={
                        <StyledTitle
                          color="gray"
                          level={4}
                        >
                          Complemento
                        </StyledTitle>
                      }
                    >
                      <Form.Item
                        name="complement"
                        noStyle
                      >
                        <Input
                          placeholder="Complemento"
                          size="large"
                        />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item
                      label={
                        <StyledTitle
                          color="gray"
                          level={4}
                        >
                          CEP
                        </StyledTitle>
                      }
                    >
                      <Form.Item
                        name="zipCode"
                        noStyle
                        rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                      >
                        <Input
                          placeholder="CEP"
                          size="large"
                        />
                      </Form.Item>
                    </Form.Item>
                  </Space>
                </Form.Item>
                <Space>
                  <Form.Item
                    label={
                      <StyledTitle
                        color="gray"
                        level={4}
                      >
                        Bairro
                      </StyledTitle>
                    }
                  >
                    <Form.Item
                      name="zone"
                      noStyle
                      rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                    >
                      <Input
                        placeholder="Bairro"
                        size="large"
                      />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    label={
                      <StyledTitle
                        color="gray"
                        level={4}
                      >
                        Cidade
                      </StyledTitle>
                    }
                  >
                    <Form.Item
                      name="city"
                      noStyle
                      rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                    >
                      <Input
                        placeholder="Cidade"
                        size="large"
                      />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item
                    label={
                      <StyledTitle
                        color="gray"
                        level={4}
                      >
                        Estado
                      </StyledTitle>
                    }
                    style={{ minWidth: "20px" }}
                  >
                    <Form.Item
                      name="state"
                      noStyle
                      rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                    >
                      <Select
                        allowClear
                        size="large"
                      >
                        {countryStates.map((countryState) => (
                          <Select.Option
                            key={countryState.abbreviation}
                            title={countryState.abbreviation}
                            value={countryState.abbreviation}
                          >
                            {countryState.abbreviation}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Form.Item>
                </Space>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">Comprovante de matrícula em ensino superior ou técnico</StyledText>,
      key: "CMEST",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "48px", marginRight: "48px", height: "100px", width: "100px" }}
              src={!data.logo ? CurrentLogo : `${REACT_APP_BASE_PATH}/download/${data.logo}`}
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "CMEST" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      Número de matrícula
                    </StyledTitle>
                  }
                >
                  <Form.Item noStyle>
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          // name="dataValue"
                          name="enrollmentEducation"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="000000000000"
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="enrollmentEducationAttachment"
                            noStyle
                            rules={[
                              {
                                required: requireDocuments.attachmentCodes.includes("CMEST"),
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "CMEST")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">Termo de compromisso de estágio (TCE)</StyledText>,
      key: "TCE",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "48px", marginRight: "48px", height: "100px", width: "100px" }}
              src={!data.logo ? CurrentLogo : `${REACT_APP_BASE_PATH}/download/${data.logo}`}
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "TCE" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      Número de registro do documento
                    </StyledTitle>
                  }
                >
                  <Form.Item noStyle>
                    <Row gutter={8}>
                      <Col span={19}>
                        <Form.Item
                          // name="dataValue"
                          name="internshipCommitmentTerm"
                          noStyle
                          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                        >
                          <Input
                            placeholder="000000000000"
                            size="large"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Space>
                          <Form.Item
                            name="internshipCommitmentTermAttachment"
                            noStyle
                            rules={[
                              {
                                required: requireDocuments.attachmentCodes.includes("TCE"),
                                message: i18n(dictionary.message.requiredAttachment),
                              },
                            ]}
                          >
                            <Upload
                              accept={"image/*"}
                              showUploadList={false}
                              multiple={false}
                              onChange={(info) => handleUpload(info, "TCE")}
                            >
                              <Button
                                type="primary"
                                size="large"
                                icon={<UploadOutlined />}
                              />
                            </Upload>
                          </Form.Item>
                          <Button
                            type="primary"
                            size="large"
                            icon={<EyeOutlined />}
                            onClick={() => {
                              Modal.info({
                                title: "Visualização",
                                content: <Image src={urlUploadedFile} />,
                              });
                            }}
                          />
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form.Item>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    {
      title: <StyledText color="white">Gênero</StyledText>,
      key: "GE",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "48px", marginRight: "48px", height: "100px", width: "100px" }}
              src={!data.logo ? CurrentLogo : `${REACT_APP_BASE_PATH}/download/${data.logo}`}
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row>
            <Space
              direction="horizontal"
              style={{ width: "100%", justifyContent: "center" }}
            >
              <Form
                form={form}
                name="nest-messages"
                onFinish={(values) => onFinish({ ...values, documentCode: "GE" })}
                style={{ maxWidth: 600, marginTop: 8 }}
                validateMessages={validateMessages}
                layout="vertical"
              >
                <Form.Item
                  label={
                    <StyledTitle
                      color="gray"
                      level={2}
                    >
                      Gênero
                    </StyledTitle>
                  }
                >
                  <Form.Item
                    name="dataValue"
                    noStyle
                    rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                  >
                    <Select
                      placeholder="Escolha o seu gênero"
                      size="large"
                    >
                      <Select.Option value="1">Mulher cisgênero</Select.Option>
                      <Select.Option value="2">Homem cisgênero</Select.Option>
                      <Select.Option value="3">Mulher transgênero</Select.Option>
                      <Select.Option value="4">Homem transgênero</Select.Option>
                      <Select.Option value="5">Não-binário</Select.Option>
                      <Select.Option value="6">Agênero</Select.Option>
                      <Select.Option value="7">Bigênero</Select.Option>
                      <Select.Option value="8">Trigênero</Select.Option>
                      <Select.Option value="9">Pangênero</Select.Option>
                      <Select.Option value="11">Outros</Select.Option>
                      <Select.Option value="12">Prefiro não declarar</Select.Option>
                    </Select>
                  </Form.Item>
                </Form.Item>
              </Form>
            </Space>
          </Row>
        </Space>
      ),
    },
    // {
    //   title: <StyledText color="white">Contrato</StyledText>,
    //   key: "CO",
    //   content: (
    //     <Space
    //       direction="vertical"
    //       style={{ display: "flex" }}
    //     >
    //       <Space
    //         style={{
    //           width: "100%",
    //           justifyContent: "end",
    //           marginBottom: "4%",
    //           textAlign: "end",
    //         }}
    //       >
    //         <Image
    //           style={{ marginTop: "48px", marginRight: "48px", height: "100px", width: "100px" }}
    //           src={!data.logo ? CurrentLogo : `${REACT_APP_BASE_PATH}/download/${data.logo}`}
    //           alt="Logo"
    //           preview={false}
    //         />
    //       </Space>
    //       <Row>
    //         <Space
    //           direction="vertical"
    //           style={{ marginLeft: "8%", marginRight: "8%", width: "100%" }}
    //         >
    //           <StyledTitle
    //             color="gray"
    //             level={2}
    //           >
    //             Contrato
    //           </StyledTitle>
    //           <Card style={{ width: "100%" }}>
    //             {requireDocuments.contractText && (
    //               <RichTextEditor
    //                 content={JSON.parse(requireDocuments.contractText)}
    //                 style={{
    //                   height: 290,
    //                 }}
    //                 readOnly
    //               />
    //             )}
    //           </Card>
    //           <Form
    //             form={form}
    //             name="nest-messages"
    //             onFinish={(values) => onFinish({ ...values, documentCode: "CO" })}
    //             style={{ width: "100%", marginTop: 8, justifyContent: "center", textAlign: "center" }}
    //             validateMessages={validateMessages}
    //           >
    //             <Form.Item>
    //               <Form.Item name="accept-contract">
    //                 <Checkbox onChange={({ target }) => setEnableNext(target.checked)}>
    //                   <Text>Li e aceito os termos do contrato de trabalho</Text>
    //                 </Checkbox>
    //               </Form.Item>
    //             </Form.Item>
    //           </Form>
    //         </Space>
    //       </Row>
    //     </Space>
    //   ),
    // },
    {
      title: <StyledText color="white">Enviar</StyledText>,
      key: "EN",
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex" }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginBottom: "20%",
              textAlign: "end",
            }}
          >
            <Image
              style={{ marginTop: "48px", marginRight: "48px", height: "100px", width: "100px" }}
              src={!data.logo ? CurrentLogo : `${REACT_APP_BASE_PATH}/download/${data.logo}`}
              alt="Logo"
              preview={false}
            />
          </Space>
          <Row style={{ textAlign: "center" }}>
            <Space
              direction="horizontal"
              style={{
                width: "100%",
                justifyContent: "center",
                marginLeft: "16%",
                marginRight: "16%",
                height: "60%",
                marginBottom: "64px",
              }}
            >
              <StyledTitle
                color="gray"
                level={5}
              >
                Antes de enviar verifique se todos os documentos foram incluídos/anexados. Ao clicar no botão abaixo os
                seus documentos serão enviados e logo revisados.
              </StyledTitle>
            </Space>
          </Row>
        </Space>
      ),
    },
  ];

  const filteredSteps = steps.filter(
    (step) => requireDocuments.documentCodes.includes(step.key) || ["CO", "EN"].includes(step.key),
  );

  const items = filteredSteps.map((item) => ({ key: item.key, title: item.title }));

  return (
    <>
      {loadingData && (
        <Flex
          style={{ height: "100vh", width: "100vw" }}
          align="center"
          justify="center"
        >
          <Spin size="large" />
        </Flex>
      )}
      {current === -1 && !loadingData && (
        <PublicEmployeeStartPage
          next={next}
          data={data}
          disabledStart={withoutRequireDocuments}
        />
      )}
      {current >= 0 && !loadingData && (
        <Row style={rowStyle}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
            style={stepsStyle}
          >
            <Row>
              <Col span={16}>
                <Space direction="vertical">
                  <Space
                    direction="horizontal"
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      marginLeft: "16%",
                      marginRight: "16%",
                      height: "60%",
                      marginTop: "15%",
                    }}
                  >
                    <StyledTitle
                      color="white"
                      level={3}
                    >
                      {i18n(dictionary.label.document_list)}
                    </StyledTitle>
                  </Space>
                  <Space
                    direction="horizontal"
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      marginLeft: "16%",
                      marginRight: "16%",
                      height: "50%",
                    }}
                  >
                    <Steps
                      current={current}
                      items={items}
                      direction="vertical"
                    />
                  </Space>
                </Space>
              </Col>
              <Col span={8}>
                <Space align="end">
                  <Image
                    width="auto"
                    height="50%"
                    src={SendingDocument}
                    alt="SendingDocument"
                    preview={false}
                  />
                </Space>
              </Col>
            </Row>
            <Row style={contentBottom}>
              <Row>
                <StyledTitle color="white">{data.pagePhrase}</StyledTitle>
              </Row>
            </Row>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
          >
            <div>{filteredSteps[current].content}</div>
            <Row>
              <Space
                direction="horizontal"
                style={{ width: "100%", justifyContent: "center" }}
              >
                <div style={{ marginTop: 24 }}>
                  {current > 0 && current < filteredSteps.length - 1 && (
                    <Button
                      type="primary"
                      onClick={() => prev()}
                      style={{ margin: "0 8px" }}
                      size="large"
                      disabled={uploadingFile}
                      icon={<ArrowLeftOutlined />}
                    >
                      Voltar
                    </Button>
                  )}
                  {current < filteredSteps.length - 1 && (
                    <Button
                      type="primary"
                      onClick={() => {
                        form.submit();
                        cleanImage();
                      }}
                      style={{ margin: "0 8px" }}
                      size="large"
                      disabled={(!enableNext && filteredSteps[current].key === "CO") || uploadingFile}
                      icon={<ArrowRightOutlined />}
                    >
                      Próximo
                    </Button>
                  )}
                  {current === filteredSteps.length - 1 && (
                    <>
                      <Button
                        type="primary"
                        onClick={() => prev()}
                        style={{ background: "#6164C2" }}
                        size="large"
                      >
                        <ArrowLeftOutlined />
                        Voltar
                      </Button>
                      <Button
                        type="primary"
                        onClick={async () => {
                          message.success("Etapas concluídas!");
                          await onFinishSendDocument();
                          setDocumentsSended(true);
                        }}
                        style={{ margin: "0 8px", background: "#6164C2" }}
                        size="large"
                        icon={<ArrowUpOutlined />}
                        disabled={!enableSend || documentsSended}
                      >
                        Enviar
                      </Button>
                    </>
                  )}
                </div>
              </Space>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Space
                direction="horizontal"
                style={{
                  width: "100%",
                  justifyContent: "center",
                  marginLeft: "16%",
                  marginRight: "16%",
                  height: "60%",
                  marginTop: "64px",
                }}
              >
                {current < filteredSteps.length - 1 ? (
                  <StyledTitle
                    color="gray"
                    level={5}
                  >
                    Faltam {requireDocuments.deadline} dias para expirar o prazo de envio!
                  </StyledTitle>
                ) : (
                  <Checkbox onChange={({ target }) => setEnableSend(target.checked)}>
                    <Text>Aceito os</Text>
                    <Link
                      href="https://www.qrpoint.com.br/politica-de-privacidade/"
                      underline
                      target="_blank"
                    >
                      {" "}
                      termos de uso e política de privacidade{" "}
                    </Link>
                    <Text>para o envio dos documentos.</Text>
                  </Checkbox>
                )}
              </Space>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
}
