import axios from "axios";
import { REACT_APP_OCR_API_TOKEN, REACT_APP_OCR_API_URL } from "src/constants";

const api = axios.create({
  baseURL: REACT_APP_OCR_API_URL,
  timeout: 10000, // 10 segundos
});

export async function recognize(file: File) {
  const formData = new FormData();
  formData.append("apikey", REACT_APP_OCR_API_TOKEN);
  formData.append("file", file);
  formData.append("language", "por");
  formData.append("scale", "true");
  formData.append("OCREngine", "2");

  const response = await api
    .post("/parse/image", formData)
    .then((response) => (response.status === 200 && response.data ? response.data : null))
    .catch((error) => {
      console.error(error);
      return null;
    });
  if (!response || !response.ParsedResults.length) return null;

  return response.ParsedResults[0].ParsedText;
}
