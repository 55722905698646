import { Flex, Typography } from "antd";
import { CSSProperties } from "react";
import COLOR from "src/styles/colors";

const { Title } = Typography;

const titleStyle: CSSProperties = {
  margin: 0,
};

type CustomBreadcrumbProps = {
  root: string | null;
  title: string;
};

export default function CustomBreadcrumb({ root, title }: CustomBreadcrumbProps) {
  return (
    <Flex
      style={{ cursor: "default" }}
      gap={5}
      justify="center"
      align="center"
    >
      {root && (
        <>
          <Title
            type="secondary"
            style={titleStyle}
            level={4}
          >
            {root}
          </Title>
          <Title
            type="secondary"
            style={titleStyle}
            level={4}
          >
            /
          </Title>
        </>
      )}
      <Title
        style={{ ...titleStyle, color: COLOR.PURPLE }}
        level={4}
      >
        {title}
      </Title>
    </Flex>
  );
}
