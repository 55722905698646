import { SearchOutlined } from "@ant-design/icons";
import { Select } from "antd";
import Fuse from "fuse.js";
import { useState } from "react";
import { useStores } from "src/hooks";
import { MenuItem } from "src/pages/_layouts/default";
import { dictionary, i18n } from "src/utils";

const { Option } = Select;

const configureMenu = (menu: any, config: any) => {
  let jsonMenuTratado = [];

  for (var opt of menu) {
    if (opt.children) {
      for (var optSubItem of opt.children) {
        if (optSubItem.onClick /*&& !optSubItem.popup*/) {
          jsonMenuTratado.push({
            route: optSubItem.onClick,
            title: optSubItem.label,
            id: optSubItem.key,
            subItem: true,
            modal: false /*optSubItem.popup*/, //Deve abrir via modal?
            parent: opt.label,
          });
        }
      }
    } else if (opt.onClick) {
      jsonMenuTratado.push({
        route: opt.onClick,
        title: opt.label,
        id: opt.key,
        subItem: true,
        modal: false /*optSubItem.popup*/, //Deve abrir via modal?
      });
    }
  }

  // const menuConfigs = JSON.parse(config).itensMenu;
  // if (menuConfigs.length > 0) {
  //   jsonMenuTratado = jsonMenuTratado.filter((item) => {
  //     return (menuConfigs.indexOf(parseInt(item.id)) >= 0 && item.subItem) || !item.subItem;
  //   });
  // }

  return jsonMenuTratado;
};

export default function NavbarSearch({ menuItems, config = {} }: { menuItems?: MenuItem[]; config?: any }) {
  const menu = configureMenu(menuItems, config);

  const [data, setData] = useState<any>([]);
  const { navigation } = useStores();

  const fetchUser = (value: string) => {
    let options = {
      keys: [
        {
          name: "title",
          weight: 0.7,
        },
        {
          name: "parent",
          weight: 0.3,
        },
      ],
    };

    const fuse = new Fuse(menu, options);
    setData(fuse.search(value));
  };

  const handleChange = (value: any, item: any) => {
    const { data } = item[0].props;
    if (data.route) {
      navigation.setSelectedMenu(value[0].key);
      data.route();
    }
    setData([]);
  };

  return (
    <div
      className="header-searcher"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        verticalAlign: "middle",
        width: "100%",
      }}
    >
      <Select
        className="header-searcher"
        mode="multiple"
        labelInValue
        value={[]}
        placeholder={i18n(dictionary.do_search)}
        notFoundContent={null}
        filterOption={false}
        onSearch={fetchUser}
        onChange={handleChange}
        suffixIcon={
          <SearchOutlined
            style={{
              color: "#884EA6",
              fontSize: "15px",
            }}
          />
        }
        style={{ width: "100%", minWidth: 175, maxWidth: 500 }}
      >
        {data.map((d: any) => (
          <Option
            key={d.item.id}
            data={{ ...d.item }}
          >
            {d.item.title}
          </Option>
        ))}
      </Select>
    </div>
  );
}
