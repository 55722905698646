import { DeleteOutlined, EditOutlined, PlusOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Flex, Table, notification } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdmissionFlowApi } from "src/api";
import { fetchAdmissionFlows } from "src/api/auth/admission-flow.api";
import { Card } from "src/components";
import CustomBreadcrumb from "src/components/CustomBreadcrumb/CustomBreadcrumb";
import TextFilterColumn from "src/components/TextFilterColumn/TextFilterColumn";
import { Routes } from "src/routes/routing";
import { dictionary, i18n } from "src/utils";

export default function AdmissionFlow() {
  const navigate = useNavigate();

  const [data, setData] = useState<AdmissionFlow[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [targetAdmissionFlow, setTargetAdmissionFlow] = useState("");

  const [notificationApi, contextHolder] = notification.useNotification();

  async function getData() {
    setLoading(true);
    const tempData = (await fetchAdmissionFlows()) || [];
    setData(tempData);
    setLoading(false);
  }

  async function deleteAdmissionFlow(id: string) {
    setDeleting(true);
    const deleteResponse = await AdmissionFlowApi.deleteAdmissionFlow(id);
    notificationApi.destroy();
    if (deleteResponse) {
      notificationApi.success({
        message: i18n(dictionary.success.ADMISSION_FLOW_DELETE_SUCCESS.message),
        description: i18n(dictionary.success.ADMISSION_FLOW_DELETE_SUCCESS.description),
      });
      getData();
    } else {
      notificationApi.error({
        message: i18n(dictionary.error.ADMISSION_FLOW_DELETE_ERROR.message),
        description: i18n(dictionary.error.ADMISSION_FLOW_DELETE_ERROR.description),
      });
    }
    setDeleting(false);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Card
      title={
        <CustomBreadcrumb
          root={i18n(dictionary.menu.registration)}
          title={i18n(dictionary.menu.admissionFlow)}
        />
      }
      actions={
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => navigate(Routes.ADMISSION_FLOW_CREATE)}
        >
          {i18n(dictionary.pages.admissionFlow.addButton)}
        </Button>
      }
    >
      {contextHolder}
      <Table
        scroll={{ x: true }}
        rowKey={({ id }) => id}
        style={{ width: "100%" }}
        loading={loading}
        dataSource={data}
        columns={[
          {
            title: i18n(dictionary.pages.admissionFlow.table.columns.name),
            dataIndex: "name",
            filterSearch: true,
            onFilter: (value, record) => {
              return record.name.toLowerCase().includes(String(value).toLowerCase());
            },
            filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#884ea6" : undefined }} />,
            filterDropdown: TextFilterColumn,
          },
          {
            title: i18n(dictionary.pages.admissionFlow.table.columns.createdAt),
            align: "center",
            dataIndex: "createdAt",
            showSorterTooltip: false,
            sorter: (a, b) => {
              return dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix();
            },
            render: (value) => dayjs(value).format(i18n(dictionary.fullDateFormat)),
          },
          {
            title: (
              <Flex
                justify="space-between"
                align="center"
                gap={!data.length ? 10 : 0}
              >
                <span style={{ flex: 1 }}>{i18n(dictionary.pages.admissionFlow.table.columns.actions.label)}</span>
                <Button
                  size="small"
                  icon={<ReloadOutlined />}
                  onClick={getData}
                />
              </Flex>
            ),
            align: "center",
            dataIndex: "id",
            width: "0px",
            render: (value) => (
              <Flex
                align="center"
                justify="center"
                gap={10}
              >
                <Button
                  size="small"
                  icon={<EditOutlined />}
                  type="primary"
                  ghost
                  onClick={() => navigate(Routes.ADMISSION_FLOW_EDIT + `/${value}`)}
                >
                  {i18n(dictionary.pages.admissionFlow.table.columns.actions.options.edit)}
                </Button>
                <Button
                  size="small"
                  icon={<DeleteOutlined />}
                  danger
                  onClick={() => {
                    setTargetAdmissionFlow(value);
                    deleteAdmissionFlow(value);
                  }}
                  loading={deleting && value === targetAdmissionFlow}
                >
                  {i18n(dictionary.pages.admissionFlow.table.columns.actions.options.delete)}
                </Button>
              </Flex>
            ),
          },
        ]}
      />
    </Card>
  );
}
