import { Layout, styled } from "src/modules";

const { Header, Content, Footer, Sider } = Layout;

export const Wrapper = styled(Layout)``;

export const WrapperContent = styled(Layout)`
  height: 100vh;
  background-color: #dbe8f4; !important;
`;

export const Sidebar = styled(Sider).attrs({
  collapsible: true,
})`
  background-color: ${({ theme }) => theme.colors.background};
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 71px;
`;

export const StyledHeader = styled(Header)`
  overflow: hidden;
  padding: 0 25px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.background};
  gap: 15px;

  margin: 16px 16px 0 16px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;

  span {
    line-height: 25px;
    font-weight: 700;
  }

  span + span,
  li + li > span {
    font-weight: 400;
  }

  aside {
    display: flex;
    flex-direction: row;
  }
`;

export const MailNotificationsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: rgba(148, 28, 154, 0.2);
  margin-left: 24px;

  @media (max-width: 400px) {
    margin: 0 10px;
  }

  svg {
    width: 15px;
    height: 15px;
  }
`;

export const NotificationsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: rgba(148, 28, 154, 0.2);

  @media (max-width: 400px) {
    margin: 0 10px;
  }

  svg {
    width: 15px;
    height: 15px;
  }
`;

export const NewsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: rgba(148, 28, 154, 0.2);

  svg {
    width: 15px;
    height: 15px;
  }
`;

export const StyledFooter = styled(Footer)`
  text-align: center;
`;

export const ChildrenContent = styled(Content)`
  margin: 0 16px 16px 16px;
  padding: 16px;
  overflow-y: auto !important;
  background: white;

  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;

  @media (max-width: 500px) {
    padding: 8px 10px;
  }
`;
