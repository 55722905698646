import { PlusOutlined } from "@ant-design/icons";
import { Button, Drawer, Flex, Form, Input, Radio, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import CreateDocumentModal from "src/components/CreateDocumentModal/CreateDocumentModal";
import RichTextEditorSlate from "src/components/RichTextEditorSlate";
import { dictionary, i18n } from "src/utils";
import { EMAIL_BODY_TEXT_FIELD } from "src/utils/enums/email-body.enum";
import PreviewSending from "../../Settings/PreviewSending";

const { Title, Text } = Typography;
const { Item } = Form;
const { TextArea } = Input;

const fieldList: EMAIL_BODY_TEXT_FIELD[] = [
  EMAIL_BODY_TEXT_FIELD.EMPLOYER_NAME,
  EMAIL_BODY_TEXT_FIELD.EMPLOYEE_NAME,
  EMAIL_BODY_TEXT_FIELD.ROLE,
  EMAIL_BODY_TEXT_FIELD.WORKLOAD,
  EMAIL_BODY_TEXT_FIELD.SALARY,
  EMAIL_BODY_TEXT_FIELD.BENEFITS,
];

interface EditAdmissionFlowStepProps {
  open: boolean;
  close: () => void;
  data: AdmissionFlowStepPayload;
  setData: React.Dispatch<React.SetStateAction<AdmissionFlowStepPayload>>;
  setSteps: React.Dispatch<React.SetStateAction<AdmissionFlowStepPayload[]>>;
  primaryColor: string;
  secondaryColor: string;
  imageUrl?: string;
  documents: Contract[];
  setDocuments: React.Dispatch<React.SetStateAction<Contract[]>>;
}

export default function EditAdmissionFlowStep({
  open,
  close,
  data,
  setData,
  setSteps,
  primaryColor,
  secondaryColor,
  imageUrl,
  documents,
  setDocuments,
}: EditAdmissionFlowStepProps) {
  const [forceRenderKey, setForceRenderKey] = useState(0);

  // Controla o aparecimento do modal de criação de novo documento;
  const [openCreateDocumentModal, setOpenCreateDocumentModal] = useState(false);

  function handleClose() {
    setSteps((prevSteps) => {
      let tempSteps = [...prevSteps];
      tempSteps[data.index] = { ...data };
      return tempSteps;
    });
    close();
  }

  useEffect(() => {
    // Força renderização do componente RichTextEditorSlate
    if (open) setForceRenderKey((prevKey) => prevKey + 1);
  }, [open]);

  return (
    <>
      <CreateDocumentModal
        open={openCreateDocumentModal}
        onCancel={() => setOpenCreateDocumentModal(false)}
        setDocuments={setDocuments}
        setData={setData}
      />
      <Drawer
        width={600}
        open={open}
        onClose={handleClose}
        title={
          <Flex
            align="center"
            justify="center"
            gap={10}
          >
            <Text>
              {i18n(dictionary.pages.admissionFlowCreate.tabs.t1.step.label)}&nbsp;
              {Number(data.index + 1)}
            </Text>
            <Text type="secondary">{"➝"}</Text>
            <Title
              style={{ margin: 0, color: "#884ea6" }}
              level={5}
            >
              {i18n(dictionary.pages.admissionFlowCreate.tabs.t1.cards.others.titles[data.stepId])}
            </Title>
          </Flex>
        }
      >
        <Form layout="vertical">
          {/* Assunto do e-mail */}
          <Item label={i18n(dictionary.pages.admissionFlowCreate.tabs.t1.step.fields.subject)}>
            <Input
              maxLength={50}
              onChange={({ target }) => setData((prevData) => ({ ...prevData, emailSubject: target.value }))}
              value={data.emailSubject}
            />
          </Item>
          {/* Texto do e-mail */}
          <Item label={i18n(dictionary.pages.admissionFlowCreate.tabs.t1.step.fields.text)}>
            <RichTextEditorSlate
              key={forceRenderKey}
              content={JSON.parse(data.emailBody)}
              fieldList={fieldList}
              onChange={(value) =>
                setData((prevData) => ({
                  ...prevData,
                  emailBody: JSON.stringify(value),
                }))
              }
              placeHolder={""}
              selectWithMarginTop
            />
          </Item>
          {[2, 4, 6].includes(data.stepId) && (
            <>
              {/* Documento */}
              <Item
                label={i18n(
                  dictionary.pages.admissionFlowCreate.tabs.t1.step.fields.document.select[
                    data.stepId as StepWithDocumentAndValidation
                  ],
                )}
              >
                <Flex gap={10}>
                  <Select
                    value={data.documentId}
                    options={documents.map((option) => ({ value: option.id, label: option.description }))}
                    onChange={(option) => setData((prevData) => ({ ...prevData, documentId: option }))}
                  />
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() => setOpenCreateDocumentModal(true)}
                  >
                    {i18n(dictionary.pages.admissionFlowCreate.tabs.t1.step.fields.document.button)}
                  </Button>
                </Flex>
              </Item>
              {/* Forma de validação */}
              <Item label={i18n(dictionary.pages.admissionFlowCreate.tabs.t1.step.fields.validationType.label)}>
                <Radio.Group
                  value={data.validationType}
                  onChange={({ target }) => setData((prevData) => ({ ...prevData, validationType: target.value }))}
                >
                  <Radio value={1}>
                    {i18n(dictionary.pages.admissionFlowCreate.tabs.t1.step.fields.validationType[1])}
                  </Radio>
                  <Radio value={2}>
                    {i18n(dictionary.pages.admissionFlowCreate.tabs.t1.step.fields.validationType[2])}
                  </Radio>
                </Radio.Group>
              </Item>
            </>
          )}
          {data.stepId !== 3 && (
            <>
              {/* Frase da landing page */}
              <Item label={i18n(dictionary.pages.admissionFlowCreate.tabs.t1.step.fields.phrase.label)}>
                <Input
                  maxLength={255}
                  onChange={({ target }) => setData((prevData) => ({ ...prevData, pagePhrase: target.value }))}
                  value={data.pagePhrase}
                />
              </Item>
              {/* Descrição da landing page */}
              <Item label={i18n(dictionary.pages.admissionFlowCreate.tabs.t1.step.fields.description.label)}>
                <TextArea
                  style={{ resize: "none", height: 62 }}
                  maxLength={100}
                  onChange={({ target }) => setData((prevData) => ({ ...prevData, pageDescription: target.value }))}
                  value={data.pageDescription}
                />
              </Item>
              {/* Preview da landing page */}
              <div style={{ border: "1px solid #d9d9d9", marginTop: 20 }}>
                <PreviewSending
                  logoCompany={imageUrl}
                  principalColor={primaryColor}
                  secondaryColor={secondaryColor}
                  frase={data.pagePhrase}
                  description={data.pageDescription}
                  optionPreview="medium"
                />
              </div>
            </>
          )}
        </Form>
      </Drawer>
    </>
  );
}
