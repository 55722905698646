import { ClockCircleOutlined, FileDoneOutlined, SunOutlined } from "@ant-design/icons";
import { Tabs, TabsProps } from "antd";
import CryptoJS from "crypto-js";
import dayjs from "dayjs";
import { useState } from "react";
import { Storage } from "src/services";
import { dictionary, i18n } from "src/utils/i18n";

export default function SystemSelector() {
  const [clicked, setClicked] = useState(false);

  const onChange = (key: string) => {
    switch (key) {
      case "2":
        redirect(String(Storage.getUserId()), 2);
        break;

      case "3":
        redirect(JSON.stringify({ userId: Storage.getUserId(), expiration: dayjs().add(5, "minutes") }), 3);
        break;

      default:
        break;
    }
  };

  function redirect(data: string, system: number) {
    const secretKey = process.env.REACT_APP_KEY_ENCRYPT_URL;
    if (secretKey && data) {
      const encryptedData = CryptoJS.AES.encrypt(data, secretKey);
      const encodedEncryptedData = encodeURIComponent(encryptedData.toString());
      switch (system) {
        case 2:
          return window.location.replace(
            `${process.env.REACT_APP_QRPOINT_ENDPOINT}/fast-login?data=${encodedEncryptedData}`,
          );
        case 3:
          return window.location.replace(`${process.env.REACT_APP_VACATION_ENDPOINT}/login/${encodedEncryptedData}`);
      }
    }
  }

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: i18n(dictionary.components.systemSelector.admission),
      icon: <FileDoneOutlined />,
    },
    {
      key: "2",
      label: i18n(dictionary.components.systemSelector.timesheet),
      icon: <ClockCircleOutlined />,
    },
    {
      key: "3",
      label: i18n(dictionary.components.systemSelector.vacation),
      icon: <SunOutlined />,
    },
  ];

  return (
    <Tabs
      style={{
        width: "100%",
        maxWidth: 460,
        height: 50,
        minWidth: 180,
        overflow: "hidden",
      }}
      tabBarStyle={{ margin: 0 }}
      size="small"
      defaultActiveKey="1"
      items={items}
      onChange={onChange}
    />
  );
}
