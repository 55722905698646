import { LogoutOutlined, NotificationOutlined, SettingOutlined } from "@ant-design/icons";
import { Dropdown, Flex } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useIntercom } from "react-use-intercom";
import { useStores } from "src/hooks";
import { Routes } from "src/routes";
import { Storage } from "src/services";
import COLOR from "src/styles/colors";
import { dictionary, i18n, removeHashFromColor } from "src/utils";

const AccountDropdown: React.FC = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState<string>("");
  const { auth } = useStores();
  const { shutdown, boot } = useIntercom();

  const onSignOut = async (): Promise<void> => {
    await auth.logout();
    shutdown();
    boot();
    navigate(Routes.LOGIN);
  };

  useEffect(() => {
    setUserName(Storage.getUserName());
  }, []);

  return (
    <Flex
      className="header-user-container"
      justify="center"
      align="center"
      gap={10}
    >
      <span
        className="header-user-name"
        style={{
          fontWeight: "normal",
          whiteSpace: "nowrap",
        }}
      >
        {i18n(dictionary.hello, { item: userName })}
      </span>
      <Dropdown
        overlayStyle={{ justifyContent: "space-between" }}
        menu={{
          items: [
            {
              key: 1,
              label: i18n(dictionary.menu.setting),
              icon: <SettingOutlined />,
              onClick: () => navigate(Routes.SETTINGS),
            },
            {
              key: 2,
              label: i18n(dictionary.menu.new),
              icon: <NotificationOutlined />,
              onClick: () => navigate(Routes.NEWS),
            },
            {
              key: 3,
              label: i18n(dictionary.menu.exit),
              icon: <LogoutOutlined />,
              onClick: onSignOut,
            },
          ],
        }}
      >
        <img
          width={32}
          height={32}
          src={`https://ui-avatars.com/api/?rounded=true&format=svg&background=${removeHashFromColor(
            COLOR.PURPLE,
          )}&color=${removeHashFromColor("#F5F6FA")}&name=${userName.substring(0, 1)}`}
          alt={userName}
        />
      </Dropdown>
    </Flex>
  );
};

export default AccountDropdown;
