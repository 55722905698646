import { BgColorsOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, ColorPicker, Drawer, Flex, Form, Typography, Upload, message } from "antd";
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from "antd/es/upload";
import { UserSendingConfigurations } from "src/api";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import { REACT_APP_BASE_PATH } from "src/constants";
import { ExpiredSessionError, dictionary, getBase64, i18n } from "src/utils";
import PreviewSending from "../../Settings/PreviewSending";

const { Title } = Typography;

interface EditAdmissionFlowVisualSettingsProps {
  open: boolean;
  close: () => void;
  loadingLogo: boolean;
  setLoadingLogo: (loadingLogo: boolean) => void;
  imageUrl?: string;
  setImageUrl: (imageUrl: string) => void;
  primaryColor: string;
  setPrimaryColor: (primaryColor: string) => void;
  secondaryColor: string;
  setSecondaryColor: (secondaryColor: string) => void;
}

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Você só pode fazer upload de arquivos JPG/PNG!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("A imagem deve ter menos de 2 MB!");
  }
  return isJpgOrPng && isLt2M;
};

export default function EditAdmissionFlowVisualSettings({
  open,
  close,
  imageUrl,
  setImageUrl,
  loadingLogo,
  setLoadingLogo,
  primaryColor,
  setPrimaryColor,
  secondaryColor,
  setSecondaryColor,
}: EditAdmissionFlowVisualSettingsProps) {
  const handleChange: UploadProps["onChange"] = (info: UploadChangeParam<UploadFile>) => {
    setLoadingLogo(true);
    if (info.event) {
      getBase64(info.file.originFileObj as RcFile, (url) => {
        sendUpload(url);
        setLoadingLogo(false);
      });
    }
  };

  function sendUpload(url: any) {
    const fileBuffer = url.replace(/(data:image\/(jpeg)*(gif)*(png)*(gif)*\;(base64),)/g, "");
    UserSendingConfigurations.uploadLogo(fileBuffer)
      .then((res: any) => {
        setImageUrl(res.url);
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.ATTACH_IMAGE_ERROR.title),
            message: i18n(dictionary.error.ATTACH_IMAGE_ERROR.message),
            onClickLink: () => {},
            onOk: () => sendUpload(url),
          });
        }
        console.error(error);
      });
  }

  const uploadButton = (
    <div>
      {loadingLogo ? <LoadingOutlined /> : <PlusOutlined style={{ fontWeight: "bold" }} />}
      <div style={{ marginTop: 8 }}>{i18n(dictionary.components.sendings.formItems.logo)}</div>
    </div>
  );

  return (
    <Drawer
      width={600}
      open={open}
      onClose={close}
      title={
        <Flex
          align="center"
          justify="center"
        >
          <Title
            style={{ margin: 0, color: "#884ea6" }}
            level={5}
          >
            {i18n(dictionary.pages.admissionFlowCreate.tabs.t1.cards.settings.title)}
          </Title>
        </Flex>
      }
    >
      <Form layout="vertical">
        <Flex
          style={{ marginBottom: 20 }}
          gap={10}
          justify="space-around"
          align="center"
        >
          <Form.Item
            name="logo"
            className="sending-components"
            label={i18n(dictionary.components.sendings.formItems.logo)}
            style={{ fontWeight: 600 }}
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img
                  src={`${REACT_APP_BASE_PATH}/download/${imageUrl}`}
                  alt="avatar"
                  style={{ width: "100%" }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
          <Flex>
            <Form.Item
              name="primaryColor"
              label={i18n(dictionary.components.sendings.formItems.principalColor)}
              style={{ fontWeight: 600 }}
            >
              <ColorPicker
                size="large"
                value={primaryColor}
                onChangeComplete={(color) => setPrimaryColor(color.toHexString())}
              >
                <Card
                  style={{
                    border: "none",
                    padding: "16px",
                    width: 60,
                    height: 60,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: primaryColor,
                  }}
                >
                  <BgColorsOutlined style={{ fontSize: 30, color: "white" }} />
                </Card>
              </ColorPicker>
            </Form.Item>
            <Form.Item
              name="secondaryColor"
              label={i18n(dictionary.components.sendings.formItems.secondaryColor)}
              style={{ fontWeight: 600 }}
            >
              <ColorPicker
                size="large"
                value={secondaryColor}
                onChangeComplete={(color) => setSecondaryColor(color.toHexString())}
              >
                <Card
                  style={{
                    border: "none",
                    padding: "16px",
                    width: 60,
                    height: 60,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: secondaryColor,
                  }}
                >
                  <BgColorsOutlined style={{ fontSize: 30, color: "white" }} />
                </Card>
              </ColorPicker>
            </Form.Item>
          </Flex>
        </Flex>
        <div style={{ border: "1px solid #d9d9d9" }}>
          <PreviewSending
            logoCompany={imageUrl}
            principalColor={primaryColor}
            secondaryColor={secondaryColor}
            frase={i18n(dictionary.components.sendings.formItems.frasePlaceHolder)}
            description={i18n(dictionary.components.sendings.formItems.descriptionPlaceholder)}
            optionPreview="medium"
          />
        </div>
      </Form>
    </Drawer>
  );
}
